import { logEventToMongodb } from '../redux/services/eventService';
import { store } from '../redux/store';
import { EMongodbEventType, IMongodbEvent } from '../types/mongodb';

export function logEvent(eventData: { type: EMongodbEventType; payload?: any }) {
  try {
    const now = new Date();

    const user = store.getState()?.Auth?.currentUser;
    const pathname = window.location.pathname;

    const event: Partial<IMongodbEvent> = {
      createdOn: { $date: now.toISOString() } as any,
      serverCreatedOn: { $date: now.toISOString() } as any,
      screenContext: pathname,
      type: eventData.type,
    };

    if (eventData.payload) {
      event['payload'] = eventData.payload;
    }

    if (user) {
      event['performedBy'] = {
        email: user.email ?? '',
        name: user.name,
      };
    }

    logEventToMongodb(event);
  } catch (err) {
    console.log('error: ', err);
  }
}
