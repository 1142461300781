import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { StoreActions } from '../../redux/actions';
import { EReduxAsyncState } from '../../types/redux';

export const useFetchStores = (fetch = false) => {
  const dispatch = useAppDispatch();
  const stores = useAppSelector(state => state.Store.stores);

  useEffect(() => {
    if (!stores.data?.length && fetch && stores.status === EReduxAsyncState.idle) {
      dispatch(StoreActions.fetchStores.saga());
    }
  }, []);

  return stores;
};
