import { useEffect, useRef } from 'react';

/**
 * To use this hook add google Maps API script in webpage <head>
 *
 * <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places"><script>
 *
 * Ref - https://developers.google.com/maps/documentation/javascript/place-autocomplete#javascript
 *
 */

interface IUsePlacesAutoCompleteWidgetProps {
  options?: google.maps.places.AutocompleteOptions;
  onPlaceSelected?: (
    places: google.maps.places.PlaceResult,
    ref: React.RefObject<HTMLInputElement>,
    autocomplete: google.maps.places.Autocomplete,
  ) => void;
}
export const usePlacesAutoCompleteWidget = ({
  options = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' },
    fields: ['geometry', 'address_components', 'formatted_address', 'type'],
  },
  onPlaceSelected,
}: IUsePlacesAutoCompleteWidgetProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handelPlacesChanged = (autocomplete: google.maps.places.Autocomplete) => {
    const result = autocomplete.getPlace();
    if (autocompleteRef.current) {
      onPlaceSelected?.(result, inputRef, autocompleteRef.current);
    }
  };

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined' && window.document;

    if (!inputRef.current || !isBrowser) return;

    let listenerIdentifier: google.maps.MapsEventListener;

    if (inputRef.current && window && window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);
      autocompleteRef.current = autocomplete;
      listenerIdentifier = autocomplete.addListener('place_changed', () => {
        handelPlacesChanged(autocomplete);
      });
    }

    return () => {
      if (listenerIdentifier) {
        google.maps.event.removeListener(listenerIdentifier);
      }
    };
  }, [inputRef.current]);

  return {
    inputRef,
  };
};
