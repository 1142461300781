import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { AppState } from '../reducers/rootReducer';
import { EMongodbStoreBopisStatus } from '../../types/mongodb';

const _storeState = (state: AppState) => state.Store;

export const selectStores = createDraftSafeSelector(
  _storeState,
  payload => payload.stores?.data || [],
);

export const bopisStores = createDraftSafeSelector(selectStores, stores =>
  stores.filter(store => store.bopisStatus === EMongodbStoreBopisStatus.Active),
);

const _storeForCurrentUser = (state: AppState) => state.Store.storesForUser;
export const selectStoreForCurrentUser = createDraftSafeSelector(
  _storeForCurrentUser,
  payload => payload,
);
