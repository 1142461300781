import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { InventoryActions } from '../actions';

import { ActionType } from 'typesafe-actions';
import { getProductsInventories } from '../services/inventoryService';
import { selectStores } from '../selectors/storesSelector';
import { IStore } from '../../types';

export function* getProductsInventoriesSaga(
  action: ActionType<typeof InventoryActions.getProductsInventories.saga>,
): Generator<unknown, void, unknown> {
  try {
    const { locationCode, productsSku } = action.payload;
    const stores: any = yield select(selectStores);
    const selectedStore = (stores as IStore[]).find(store => store.locationCode === locationCode);
    if (selectedStore) {
      yield put(InventoryActions.getProductsInventories.start());
      const data: any = yield call(
        getProductsInventories,
        locationCode.length === 3 ? [`0${locationCode}`] : [locationCode],
        productsSku,
      );
      yield put(InventoryActions.setProductsInventories({ inventories: data }));
      yield put(InventoryActions.getProductsInventories.success());
    }
  } catch (error: unknown) {
    console.log('error', error);
    InventoryActions.getProductsInventories.error(error as Error);
  }
}

function* getProductsInventoriesListener() {
  yield takeEvery(
    InventoryActions.getProductsInventories.saga.toString(),
    getProductsInventoriesSaga,
  );
}

export default function* companySaga() {
  yield fork(getProductsInventoriesListener);
}
