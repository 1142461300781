import groupBy from 'lodash/groupBy';
import { IProduct, IStore } from '../types';
import { IShopifyProduct } from '../types/shopify';
import capitalize from 'lodash/capitalize';

export const groupProductsByBopisStatus = (products: IShopifyProduct[]) => {
  const { bopis = [], nonBopis = [] } = groupBy(products || [], product => {
    if (product.bopis?.value === 'true') {
      return 'bopis';
    }
    return 'nonBopis';
  });

  return {
    bopis,
    nonBopis,
  };
};

export const getBopisProductForStore = (products: IShopifyProduct[], store: IStore) => {
  const productsGroupByBopisStatus = groupProductsByBopisStatus(products);

  if (productsGroupByBopisStatus.bopis.length) {
    return productsGroupByBopisStatus.bopis.filter(product => {
      const restrictedBopisLocations = product.restrictedBopisLocations?.value
        ? (JSON.parse(product.restrictedBopisLocations?.value) as string[])
        : [];
      if (restrictedBopisLocations.includes(store.locationCode)) {
        return false;
      }

      return true;
    });
  }
  return [];
};

export const getProductsWithSamePriceAndCategory = (
  products: IProduct[],
  currentProduct: IProduct,
) => {
  return products.filter(product => {
    if (currentProduct.id === product.id) {
      return false;
    }
    if (
      product.priceRangeV2.minVariantPrice.amount ===
        currentProduct.priceRangeV2.minVariantPrice.amount &&
      product.productType.trim().toLowerCase() === currentProduct.productType.trim().toLowerCase()
    ) {
      return true;
    }
    return false;
  });
};

export const getFormattedProductType = (productType: string) => {
  return productType
    .split('/')
    .map((term: string) => capitalize(term))
    .join(' / ');
};
