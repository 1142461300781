import { z } from 'zod';
import { StringToDateSchema } from '../common';
import { getMongodbStatusHistorySchema } from './common';

export const MongoDbOrderStatusSchema = z.enum([
  'NEEDS_PACKING',
  'PACKING_STARTED',
  'UNFULFILLABLE',
  'READY_FOR_PICKUP',
  'ORDER_SUBSTITUTE_EDIT',
  'PAYMENT_CONFIRMED',
  'PAYMENT_FAILED',
  'ALREADY_PICKED_UP',
  'CANCELED',
  'CANCELED_RESHELVING_CONFIRMED',
]);

export enum MongoDbOrderStatusEnum {
  NEEDS_PACKING = 'NEEDS_PACKING',
  PACKING_STARTED = 'PACKING_STARTED',
  UNFULFILLABLE = 'UNFULFILLABLE',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  ORDER_SUBSTITUTE_EDIT = 'ORDER_SUBSTITUTE_EDIT',
  PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  ALREADY_PICKED_UP = 'ALREADY_PICKED_UP',
  CANCELED = 'CANCELED',
  CANCELED_RESHELVING_CONFIRMED = 'CANCELED_RESHELVING_CONFIRMED',
}

export enum MongoDbOrderStatusEnumToText {
  NEEDS_PACKING = 'Needs Packing',
  PACKING_STARTED = 'Packing Started',
  UNFULFILLABLE = 'Unfulfillable',
  READY_FOR_PICKUP = 'Ready For Pickup',
  ORDER_SUBSTITUTE_EDIT = 'Order Substitute Edit',
  PAYMENT_FAILED = 'Payment Failed',
  PAYMENT_CONFIRMED = 'Payment Confirmed',
  ALREADY_PICKED_UP = 'Already Picked Up',
  CANCELED = 'Canceled',
  CANCELED_RESHELVING_CONFIRMED = 'Canceled Reshelving Confirmed',
}

export type IMongoDbOrderStatus = z.infer<typeof MongoDbOrderStatusSchema>;

const MongodbOrderFulfillmentStatusSchema = z.object({
  productId: z.string(),
  variantId: z.string(),
  sku: z.string(),
  title: z.string(),
  filledQty: z.number(),
  substitutes: z
    .array(
      z.object({
        productId: z.string(),
        variantId: z.string(),
        sku: z.string(),
        title: z.string(),
        filledQty: z.number(),
      }),
    )
    .optional(),
});

export type IMongodbOrderFulfillmentStatus = z.infer<typeof MongodbOrderFulfillmentStatusSchema>;

const MongodbOrderStatusHistorySchema = getMongodbStatusHistorySchema(MongoDbOrderStatusSchema);

export type IMongodbOrderStatusHistory = z.infer<typeof MongodbOrderStatusHistorySchema>;

export const PrintSummarySchema = z.object({
  packingSlip: z.boolean(),
  invoice: z.boolean(),
  waiverForm: z.boolean(),
  printedBy: z.object({
    email: z.string(),
    name: z.string(),
  }),
  printedOn: StringToDateSchema,
});

export type IMongodbOrderPrintSummary = z.infer<typeof PrintSummarySchema>;

export const MongodbOrderSchema = z.object({
  _id: z.string(), // Shopify Order Id
  shopifyShopId: z.string(),
  shopifyShopName: z.string(),
  fulfillmentLocationId: z.string(), // Shopify Location Id
  fulfillmentLocationCode: z.string(),
  status: MongoDbOrderStatusSchema,
  products: z.array(z.string()),
  customer: z.object({
    name: z.string(),
    email: z.string(),
  }),
  name: z.string(),
  serverUpdatedOn: StringToDateSchema,
  updatedOn: StringToDateSchema,
  markedAsReadyForPickupOn: StringToDateSchema.optional(),
  pickedOn: StringToDateSchema.optional(),
  serverCreatedOn: StringToDateSchema,
  createdOn: StringToDateSchema,
  fulfillmentStatus: z.array(MongodbOrderFulfillmentStatusSchema).optional(),
  statusHistory: z.array(MongodbOrderStatusHistorySchema).optional(), // ask vishal whether it will be optional or not?
  packBy: StringToDateSchema,
  notificationsSettings: z.object({
    phone: z.string().optional(),
    newsAndOffers: z.boolean().optional(),
    emailPickupStatus: z.boolean().optional(),
    textPickupStatus: z.boolean().optional(),
  }),
  stateIdInfo: z.object({
    stateId: z
      .object({
        idNumber: z.string().optional(),
        state: z.string().optional(),
        dob: z.string().optional(),
      })
      .optional(),
    country: z.string().optional(),
    address1: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zip: z.string().optional(),
  }),
  printSummary: PrintSummarySchema.optional(),
  triesToMakeOrderFulfilled: z.number().optional(),
  notificationStatus: z
    .object({
      firstNotificationSent: z.boolean().optional(),
      secondNotificationSent: z.boolean().optional(),
      firstPickupMissedStoreNotificationSent: z.boolean().optional(),
      secondPickupMissedStoreNotificationSent: z.boolean().optional(),
      firstDelayedPackingNotificationSent: z.boolean().optional(),
      firstDelayedPackingNotificationCreated: z.boolean().optional(),
      secondDelayedPackingNotificationCreated: z.boolean().optional(),
    })
    .optional(),
});

export type IMongodbOrder = z.infer<typeof MongodbOrderSchema>;
