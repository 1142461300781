import { Space, MenuProps, Button, Menu, Typography, ConfigProvider, Drawer, Flex } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AuthActions } from '../../redux/actions';
import { DeviceType, useDeviceType } from '../../hooks';
import { useState } from 'react';
import { logEvent } from '../../utils/EventLogger';
import { EMongodbEventType } from '../../types/mongodb';

export interface IUserDropdownProps {
  items?: MenuProps['items'];
  onClick?: MenuProps['onClick'];
  deviceType?: DeviceType;
}

const defaultMenu: IUserDropdownProps['items'] = [
  {
    key: 'logout',
    label: 'Logout',
  },
];

export const UserAction = ({ items = [], onClick }: IUserDropdownProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const deviceType = useDeviceType();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.Auth.currentUser);
  const menuItems = [...items, ...defaultMenu];
  const isMobile = deviceType === DeviceType.mobile;

  const handleButtonClick = () => {
    setDrawerOpen(true);
  };

  const handleCloseButtonClick = () => {
    setDrawerOpen(false);
  };

  const handleUserLogout = () => {
    dispatch(AuthActions.logoutAccount.saga());
  };

  const handleMenuItemClick: IUserDropdownProps['onClick'] = menuInfo => {
    const { key } = menuInfo;
    switch (key) {
      case 'logout': {
        logEvent({
          type: EMongodbEventType.Enum['bopis.click-logout'],
        });
        handleUserLogout();
        break;
      }

      default:
        break;
    }

    onClick?.(menuInfo);
  };

  return (
    <>
      <Button
        onClick={handleButtonClick}
        size="large"
        shape="round"
        type={isMobile ? 'default' : 'text'}
      >
        <Space align="center">
          {!isMobile && <span>{currentUser?.name}</span>}
          <MenuOutlined />
        </Space>
      </Button>
      <Drawer
        title={
          <Flex justify="space-between" align="center">
            <Typography.Title level={5} className=" !m-0">
              {currentUser?.name}
            </Typography.Title>
            <Button icon={<CloseOutlined />} type="text" onClick={handleCloseButtonClick} />
          </Flex>
        }
        classNames={{ header: 'p-4' }}
        closable={false}
        open={drawerOpen}
        onClose={handleCloseButtonClick}
      >
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                activeBarBorderWidth: 0,
              },
            },
          }}
        >
          <Menu
            mode="inline"
            onClick={handleMenuItemClick}
            items={menuItems}
            selectable={false}
            className=" rounded-lg"
          />
        </ConfigProvider>
      </Drawer>
    </>
  );
};
