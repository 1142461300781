import { getCollection } from '../../realm';
import { eventChannel } from 'redux-saga';
import { EMongoDbCollection } from '../../types/mongodb';
import { IMongodbUserPreferences } from '../../types/mongodb/TypeUserPreferences';
import * as Realm from 'realm-web';

export const fetchUserPreferences = async (userId: string) => {
  const userPreferencesCollection = getCollection<IMongodbUserPreferences>(
    EMongoDbCollection.userPreferences,
  );

  const userPreferences = await userPreferencesCollection.findOne({
    _id: userId,
  });

  return (
    userPreferences &&
    (Realm.BSON.EJSON.parse(JSON.stringify(userPreferences)) as IMongodbUserPreferences)
  );
};

export const updateUserPreferences = async ({ filter, update }: { filter: any; update: any }) => {
  const userPreferencesCollection = getCollection<IMongodbUserPreferences>(
    EMongoDbCollection.userPreferences,
  );
  await userPreferencesCollection.updateOne(filter, update, { upsert: true });
};

export const mongodbUserPreferencesWatchEmitter = async (id: string) => {
  return eventChannel(emitter => {
    const userPreferencesCollection = getCollection<IMongodbUserPreferences>(
      EMongoDbCollection.userPreferences,
    );
    const changeStream = userPreferencesCollection.watch({
      filter: {
        'fullDocument._id': id,
      },
    });
    const watchData = async () => {
      try {
        for await (const change of changeStream) {
          emitter(change);
        }
      } catch (e) {
        throw e;
      }
    };
    watchData();
    return () => {
      changeStream.return('closed');
    };
  });
};
