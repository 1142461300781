import { RouteNames } from '../types';
import { createBrowserRouter } from 'react-router-dom';
import { ProtectedRouteLayout } from '../components/Layout';
import { ONE_LOGIN_ROLES, STORE_ACCESS_ROLES } from '../constants';

// Root
const RootContainer = () => import('../container/Root');

// Base App
const HomeContainer = () => import('../container/Home/Home');
const HomeLayoutContainer = () => import('../container/Home/Layout');

// Auth Containers
const AuthContainer = () => import('../container/Auth/AuthContainer');

// Fulfillment App Containers
const FulfillmentContainer = () => import('../container/Fulfillment/FulfillmentContainer');
const FulfillmentHomeContainer = () => import('../container/Fulfillment/HomeContainer');
const FulfillmentLayoutContainer = () => import('../container/Fulfillment/LayoutContainer');
const FulfillmentNeedsPackingContainer = () =>
  import('../container/Fulfillment/NeedsPackingContainer');
const FulfillmentNotificationsContainer = () =>
  import('../container/Fulfillment/NotificationsContainer');
const FulfillmentReadyForPickupContainer = () =>
  import('../container/Fulfillment/ReadyForPickupContainer');
const FulfillmentCancelledOrderContainer = () =>
  import('../container/Fulfillment/CancelledOrderContainer');
const FulfillmentFindOrderContainer = () => import('../container/Fulfillment/FindOrdersContainer');
const FulfillmentCancelledOrderDetailContainer = () =>
  import('../container/Fulfillment/CanceledOrderDeatilsContainer');
const FulfillmentReadyForPickupOrderContainer = () =>
  import('../container/Fulfillment/ReadyForPickupOrderContainer');
const NeedsPackingOrderContainer = () =>
  import('../container/Fulfillment/NeedsPackingOrderContainer');
const FulfillmentAlreadyPickedUpContainer = () =>
  import('../container/Fulfillment/AlreadyPickupContainer');
const FulfillmentAlreadyPickedUpOrderContainer = () =>
  import('../container/Fulfillment/AlreadyPickedUpOrderContainer');

// Admin App Containers
const AdminLayoutContainer = () => import('../container/Admin/LayoutContainer');
const AdminHomeContainer = () => import('../container/Admin/HomeContainer');
const AdminSettingsContainer = () => import('../container/Admin/Settings');
const AdminProductsSettingsContainer = () =>
  import('../container/Admin/Settings/ProductsContainer');
const AdminStoresSettingsContainer = () => import('../container/Admin/Settings/StoresContainer');
const AdminStoresSettingsDetailsContainer = () =>
  import('../container/Admin/Settings/StoreDetailsContainer');
const AdminUsersSettingsContainer = () => import('../container/Admin/Settings/UsersContainer');
const AdminCompanySettingsContainer = () => import('../container/Admin/Settings/CompanyContainer');
const AdminAnalyzeContainer = () => import('../container/Admin/Analyze');
const AdminAnalyzeOrdersContainer = () => import('../container/Admin/Analyze/Orders');
const AdminAnalyzeProductsContainer = () => import('../container/Admin/Analyze/Products');
const AdminAnalyzeStoresContainer = () => import('../container/Admin/Analyze/Stores');
const AdminAnalyzeUsersContainer = () => import('../container/Admin/Analyze/Users');

function getRelativePath(parentPath: RouteNames, childPath: RouteNames) {
  if (parentPath !== '/') {
    return childPath.replace(`${parentPath}/`, '');
  }
  return childPath.replace(`${parentPath}`, '');
}

export const router = createBrowserRouter([
  {
    lazy: RootContainer,
    children: [
      // public routes
      {
        path: RouteNames.Login,
        lazy: AuthContainer,
      },
      // protected routes
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: RouteNames.Home,
            lazy: HomeLayoutContainer,
            handle: {
              requiredOneLoginRoles: [
                ONE_LOGIN_ROLES.phantom_bopis_admin,
                ONE_LOGIN_ROLES.phantom_bopis_fulfillment,
              ],
            },
            children: [
              {
                index: true,
                lazy: HomeContainer,
              },
            ],
          },
          {
            path: RouteNames.Fulfillment,
            lazy: FulfillmentContainer,
            handle: {
              requiredOneLoginRoles: [ONE_LOGIN_ROLES.phantom_bopis_fulfillment],
            },
            children: [
              {
                index: true,
                lazy: FulfillmentContainer,
              },
            ],
          },
          {
            path: RouteNames.FulfillmentStore,
            lazy: FulfillmentLayoutContainer,
            handle: {
              requiredOneLoginRoles: [
                ONE_LOGIN_ROLES.phantom_bopis_admin,
                ONE_LOGIN_ROLES.phantom_bopis_fulfillment,
              ],
            },
            children: [
              {
                index: true,
                lazy: FulfillmentHomeContainer,
              },
              {
                path: getRelativePath(
                  RouteNames.FulfillmentStore,
                  RouteNames.FulfillmentStoreNeedPacking,
                ),
                handle: {
                  requiredOneLoginRoles: [
                    ONE_LOGIN_ROLES.phantom_bopis_admin,
                    ONE_LOGIN_ROLES.phantom_bopis_fulfillment,
                  ],
                  requiredStoreAccessRoles: [
                    STORE_ACCESS_ROLES.phantom_bopis_store_manager,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pack,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pickup,
                  ],
                },
                children: [
                  {
                    index: true,
                    lazy: FulfillmentNeedsPackingContainer,
                  },
                  {
                    path: getRelativePath(
                      RouteNames.FulfillmentStoreNeedPacking,
                      RouteNames.FulfillmentStoreNeedPackingOrderId,
                    ),
                    lazy: NeedsPackingOrderContainer,
                  },
                ],
              },
              {
                path: getRelativePath(
                  RouteNames.FulfillmentStore,
                  RouteNames.FulfillmentStoreNotifications,
                ),
                handle: {
                  requiredStoreAccessRoles: [
                    STORE_ACCESS_ROLES.phantom_bopis_store_manager,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pack,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pickup,
                  ],
                },
                children: [
                  {
                    index: true,
                    lazy: FulfillmentNotificationsContainer,
                  },
                ],
              },
              {
                path: getRelativePath(
                  RouteNames.FulfillmentStore,
                  RouteNames.FulfillmentStoreReadyForPickup,
                ),
                handle: {
                  requiredOneLoginRoles: [
                    ONE_LOGIN_ROLES.phantom_bopis_admin,
                    ONE_LOGIN_ROLES.phantom_bopis_fulfillment,
                  ],
                  requiredStoreAccessRoles: [
                    STORE_ACCESS_ROLES.phantom_bopis_store_manager,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pickup,
                  ],
                },
                children: [
                  {
                    index: true,
                    lazy: FulfillmentReadyForPickupContainer,
                  },
                  {
                    path: getRelativePath(
                      RouteNames.FulfillmentStoreReadyForPickup,
                      RouteNames.FulfillmentStoreReadyForPickupDetails,
                    ),
                    lazy: FulfillmentReadyForPickupOrderContainer,
                  },
                ],
              },
              {
                path: getRelativePath(
                  RouteNames.FulfillmentStore,
                  RouteNames.FulfillmentStoreAlreadyPickedUp,
                ),
                handle: {
                  requiredOneLoginRoles: [
                    ONE_LOGIN_ROLES.phantom_bopis_admin,
                    ONE_LOGIN_ROLES.phantom_bopis_fulfillment,
                  ],
                  requiredStoreAccessRoles: [
                    STORE_ACCESS_ROLES.phantom_bopis_store_manager,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pickup,
                  ],
                },
                children: [
                  {
                    index: true,
                    lazy: FulfillmentAlreadyPickedUpContainer,
                  },
                  {
                    path: getRelativePath(
                      RouteNames.FulfillmentStoreAlreadyPickedUp,
                      RouteNames.FulfillmentStoreAlreadyPickedUpOrder,
                    ),
                    lazy: FulfillmentAlreadyPickedUpOrderContainer,
                  },
                ],
              },
              {
                path: getRelativePath(
                  RouteNames.FulfillmentStore,
                  RouteNames.FulfillmentStoreCancelledOrders,
                ),
                handle: {
                  requiredOneLoginRoles: [
                    ONE_LOGIN_ROLES.phantom_bopis_admin,
                    ONE_LOGIN_ROLES.phantom_bopis_fulfillment,
                  ],
                  requiredStoreAccessRoles: [
                    STORE_ACCESS_ROLES.phantom_bopis_store_manager,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pickup,
                  ],
                },
                children: [
                  {
                    index: true,
                    lazy: FulfillmentCancelledOrderContainer,
                  },
                  {
                    path: getRelativePath(
                      RouteNames.FulfillmentStoreCancelledOrders,
                      RouteNames.FulfillmentStoreCancelledOrderDetails,
                    ),
                    lazy: FulfillmentCancelledOrderDetailContainer,
                  },
                ],
              },
              {
                path: getRelativePath(
                  RouteNames.FulfillmentStore,
                  RouteNames.FulfillmentStoreFindOrders,
                ),
                handle: {
                  requiredStoreAccessRoles: [
                    STORE_ACCESS_ROLES.phantom_bopis_store_manager,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pack,
                    STORE_ACCESS_ROLES.phantom_bopis_store_pickup,
                  ],
                },
                children: [
                  {
                    index: true,
                    lazy: FulfillmentFindOrderContainer,
                  },
                ],
              },
            ],
          },
          {
            path: RouteNames.Admin,
            lazy: AdminLayoutContainer,
            handle: {
              requiredOneLoginRoles: [ONE_LOGIN_ROLES.phantom_bopis_admin],
            },
            children: [
              {
                index: true,
                lazy: AdminHomeContainer,
              },
              {
                path: getRelativePath(RouteNames.Admin, RouteNames.AdminSettings),
                children: [
                  {
                    index: true,
                    lazy: AdminSettingsContainer,
                  },
                  {
                    path: getRelativePath(
                      RouteNames.AdminSettings,
                      RouteNames.AdminSettingsCompany,
                    ),
                    lazy: AdminCompanySettingsContainer,
                  },
                  {
                    path: getRelativePath(
                      RouteNames.AdminSettings,
                      RouteNames.AdminSettingsProducts,
                    ),
                    lazy: AdminProductsSettingsContainer,
                  },
                  {
                    path: getRelativePath(RouteNames.AdminSettings, RouteNames.AdminSettingsStores),
                    children: [
                      {
                        index: true,
                        lazy: AdminStoresSettingsContainer,
                      },
                      {
                        path: getRelativePath(
                          RouteNames.AdminSettingsStores,
                          RouteNames.AdminSettingsStoresDetails,
                        ),
                        lazy: AdminStoresSettingsDetailsContainer,
                      },
                    ],
                  },
                  {
                    path: getRelativePath(RouteNames.AdminSettings, RouteNames.AdminSettingsUsers),
                    lazy: AdminUsersSettingsContainer,
                  },
                ],
              },
              {
                path: getRelativePath(RouteNames.Admin, RouteNames.AdminAnalyze),
                children: [
                  {
                    index: true,
                    lazy: AdminAnalyzeContainer,
                  },
                  {
                    path: getRelativePath(RouteNames.AdminAnalyze, RouteNames.AdminAnalyzeOrders),
                    lazy: AdminAnalyzeOrdersContainer,
                  },
                  {
                    path: getRelativePath(RouteNames.AdminAnalyze, RouteNames.AdminAnalyzeProducts),
                    lazy: AdminAnalyzeProductsContainer,
                  },
                  {
                    path: getRelativePath(RouteNames.AdminAnalyze, RouteNames.AdminAnalyzeStores),
                    lazy: AdminAnalyzeStoresContainer,
                  },
                  {
                    path: getRelativePath(RouteNames.AdminAnalyze, RouteNames.AdminAnalyzeUsers),
                    lazy: AdminAnalyzeUsersContainer,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);
