import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { UserPreferencesActions } from '../../redux/actions';
import { EReduxAsyncState } from '../../types/redux';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';

export const FetchUserPreferences = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.Auth.currentUser);
  const userPreferences = useAppSelector(state => state.UserPreferences.userPreferences);

  useEffect(() => {
    if (currentUser?._id && !userPreferences.data) {
      dispatch(
        UserPreferencesActions.fetchUserPreferences.saga({
          _id: currentUser?._id,
        }),
      );
    }
  }, []);

  if (userPreferences.status === EReduxAsyncState.success) {
    return <Outlet />;
  }

  return <Spin size="large" fullscreen />;
};
