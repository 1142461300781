import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../../redux/helper';
import { EReduxAsyncState } from '../../types/redux';
import { UserPreferencesActions, CommonActions } from '../../redux/actions';
import { IMongodbUserPreferences } from '../../types/mongodb/TypeUserPreferences';

interface IUserPreferencesState {
  updateTablePreferences: InitialAsyncState;
  updateAdminMenuPreferences: InitialAsyncState;
  userPreferences: InitialAsyncState<IMongodbUserPreferences>;
}

const defaultUserPreferencesState: IUserPreferencesState = {
  updateAdminMenuPreferences: { status: EReduxAsyncState.idle },
  updateTablePreferences: { status: EReduxAsyncState.idle },
  userPreferences: { status: EReduxAsyncState.idle },
};

export const userPreferencesReducer = createReducer(defaultUserPreferencesState, builder => {
  asyncCaseCreator(UserPreferencesActions.fetchUserPreferences, 'userPreferences', builder);
  asyncCaseCreator(
    UserPreferencesActions.updateAdminMenuPreferences,
    'updateAdminMenuPreferences',
    builder,
  );
  asyncCaseCreator(
    UserPreferencesActions.updateTablePreferences,
    'updateTablePreferences',
    builder,
  );

  builder.addCase(UserPreferencesActions.setUserPreferences, (state, action) => {
    state.userPreferences.data = action.payload;
  });

  builder.addCase(CommonActions.clearReduxStore, (state, action) => {
    return defaultUserPreferencesState;
  });
});
