import { call, fork, put, takeEvery, take, select, takeLatest } from 'redux-saga/effects';
import { NotificationActions } from '../actions';

import { ActionType } from 'typesafe-actions';
import {
  getMongodbNotificationById,
  getNotificationsForStore,
  markNotificationAsRead,
  watchOnNotifications,
} from '../services/notificationService';
import { selectCurrentUser } from '../selectors/authSelector';
import { IMongodbNotification } from '../../types/mongodb/TypeNotification';
import { getMongodbOrderById } from '../services/orderService';
import { MongoDbOrderStatusEnum } from '../../types/mongodb';
import { RouteNames } from '../../types';
import { convertArrayToMap } from '../../utils';
import { selectNotificationCount } from '../selectors/notificationSelector';
import { EventChannel } from 'redux-saga';
import * as Realm from 'realm-web';

export function* getNotificationsForStoreSaga(
  action: ActionType<typeof NotificationActions.getNotificationsForStore.saga>,
): Generator<unknown, void, unknown> {
  try {
    const { locationCode, startValue } = action.payload;
    yield put(NotificationActions.getNotificationsForStore.start());
    const currentUser: any = yield select(selectCurrentUser);
    if (currentUser) {
      const data: any = yield call(getNotificationsForStore, locationCode, currentUser, startValue);
      // console.log('notifications got: ', data);
      if (startValue) {
        yield put(NotificationActions.setNotification({ notification: data.list }));
        yield put(NotificationActions.getNotificationsForStore.success());
      } else {
        const notificationMap = convertArrayToMap(data.list as IMongodbNotification[], '_id');
        yield put(
          NotificationActions.setNotificationCount({
            count: data.count.length ? data.count[0].totalNotificationCount : 0,
          }),
        );
        yield put(NotificationActions.getNotificationsForStore.success(notificationMap));
      }
    } else {
      yield put(NotificationActions.getNotificationsForStore.error(new Error('user not found')));
    }
  } catch (error: unknown) {
    console.log('orderSagaError', error);
    NotificationActions.getNotificationsForStore.error(error as Error);
  }
}
export function* markNotificationAsReadSaga(
  action: ActionType<typeof NotificationActions.markNotificationAsRead.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(NotificationActions.markNotificationAsRead.start());
    const { notificationId } = action.payload;
    const currentUser: any = yield select(selectCurrentUser);
    if (currentUser) {
      yield call(markNotificationAsRead, notificationId, currentUser);

      yield put(NotificationActions.markNotificationAsRead.success());
    } else {
      yield put(
        NotificationActions.markNotificationAsRead.error(new Error('current user not found')),
      );
    }
  } catch (error: unknown) {
    console.log('marking notification error', error);
    NotificationActions.markNotificationAsRead.error(error as Error);
  }
}
export function* viewNotificationSaga(
  action: ActionType<typeof NotificationActions.viewNotification.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(NotificationActions.viewNotification.start());
    const { notificationId, locationCode } = action.payload;
    const currentUser: any = yield select(selectCurrentUser);
    if (currentUser) {
      const notification: any = yield call(getMongodbNotificationById, notificationId);
      yield call(markNotificationAsRead, notificationId, currentUser);
      if (notification) {
        if (notification.resourceType === 'ORDER') {
          const order: any = yield call(getMongodbOrderById, notification.resourceId);
          if (order.status === 'ALREADY_PICKED_UP') {
            yield put(
              NotificationActions.viewNotification.success({
                url: RouteNames.FulfillmentStoreAlreadyPickedUpOrder.replace(
                  ':storeId',
                  locationCode,
                ).replace(':orderId', order._id),
              }),
            );
          } else if (order.status === MongoDbOrderStatusEnum.READY_FOR_PICKUP) {
            yield put(
              NotificationActions.viewNotification.success({
                url: RouteNames.FulfillmentStoreReadyForPickupDetails.replace(
                  ':storeId',
                  locationCode,
                ).replace(':orderId', order._id),
              }),
            );
          } else if (
            order.status === MongoDbOrderStatusEnum.CANCELED ||
            order.status === MongoDbOrderStatusEnum.CANCELED_RESHELVING_CONFIRMED
          ) {
            yield put(
              NotificationActions.viewNotification.success({
                url: RouteNames.FulfillmentStoreCancelledOrderDetails.replace(
                  ':storeId',
                  locationCode,
                ).replace(':orderId', order._id),
              }),
            );
          } else {
            yield put(
              NotificationActions.viewNotification.success({
                url: RouteNames.FulfillmentStoreNeedPackingOrderId.replace(
                  ':storeId',
                  locationCode,
                ).replace(':orderId', order._id),
              }),
            );
          }
        }
      } else {
        yield put(NotificationActions.viewNotification.error(new Error('Notification not found')));
      }
    } else {
      yield put(NotificationActions.viewNotification.error(new Error('current user not found')));
    }
  } catch (error: unknown) {
    console.log('orderSagaError', error);
    NotificationActions.viewNotification.error(error as Error);
  }
}
export function* watchOnNotificationsSaga(
  action: ActionType<typeof NotificationActions.watchOnNotifications.saga>,
): Generator<unknown, void, any> {
  yield put(NotificationActions.watchOnNotifications.start());
  const { locationCode } = action.payload;
  let chan: EventChannel<any> | undefined = yield call(watchOnNotifications, locationCode);
  try {
    const currentUser: any = yield select(selectCurrentUser);
    if (!chan) {
      throw new Error('No EventChannel');
    }
    while (true) {
      const changed: any = yield take(chan);
      console.log(`changed in notification: `, changed);
      const notificationCount: any = yield select(selectNotificationCount);
      const { fullDocument: _fullDocument, operationType } = changed;
      const fullDocument = Realm.BSON.EJSON.parse(
        JSON.stringify(_fullDocument),
      ) as IMongodbNotification;
      if (fullDocument.locationCode === locationCode) {
        if (operationType === 'update') {
          const hasCurrentUserReadNotification = fullDocument.markedAsReadBy?.find(
            (item: string) => item === currentUser.email,
          );
          console.log('hasCurrentUserReadNotification: ', hasCurrentUserReadNotification);
          if (!!hasCurrentUserReadNotification) {
            yield put(NotificationActions.deleteNotification({ id: fullDocument._id.toString() }));
            yield put(
              NotificationActions.setNotificationCount({
                count: (notificationCount as number) - 1,
              }),
            );
          }
        }
        if (operationType === 'insert') {
          yield put(NotificationActions.setNotification({ notification: fullDocument }));
          yield put(
            NotificationActions.setNotificationCount({ count: (notificationCount as number) + 1 }),
          );
        }
      }
    }
  } catch (error: unknown) {
    console.log('watch error', error);
    NotificationActions.watchOnNotifications.error(error as Error);
  } finally {
    if (chan) {
      chan.close();
    }
  }
}

function* getNotificationsForStoreListener() {
  yield takeEvery(
    NotificationActions.getNotificationsForStore.saga.toString(),
    getNotificationsForStoreSaga,
  );
}
function* markNotificationAsReadListener() {
  yield takeEvery(
    NotificationActions.markNotificationAsRead.saga.toString(),
    markNotificationAsReadSaga,
  );
}
function* viewNotificationListener() {
  yield takeEvery(NotificationActions.viewNotification.saga.toString(), viewNotificationSaga);
}
function* watchOnNotificationsListener() {
  yield takeLatest(
    NotificationActions.watchOnNotifications.saga.toString(),
    watchOnNotificationsSaga,
  );
}
export default function* orderSaga() {
  yield fork(getNotificationsForStoreListener);
  yield fork(markNotificationAsReadListener);
  yield fork(viewNotificationListener);
  yield fork(watchOnNotificationsListener);
}
