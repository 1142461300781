import { fork, put, takeEvery, call, select, takeLatest, delay, take } from 'redux-saga/effects';
import { EventChannel } from 'redux-saga';
import { UserPreferencesActions } from '../../redux/actions';
import {
  fetchUserPreferences,
  updateUserPreferences,
  mongodbUserPreferencesWatchEmitter,
} from '../../redux/services/userPreferencesServices';
import { ActionType } from 'typesafe-actions';
import * as Realm from 'realm-web';
import { IMongodbUserPreferences } from '../../types/mongodb/TypeUserPreferences';

export function* fetchUserPreferencesSaga(
  action: ActionType<typeof UserPreferencesActions.fetchUserPreferences.saga>,
): Generator<unknown, void, any> {
  const { _id } = action.payload;
  try {
    yield put(UserPreferencesActions.fetchUserPreferences.start());
    const userPreferences: Awaited<ReturnType<typeof fetchUserPreferences>> = yield call(
      fetchUserPreferences,
      _id,
    );
    yield put(UserPreferencesActions.watchUserPreferences({ _id }));
    yield put(UserPreferencesActions.fetchUserPreferences.success(userPreferences || undefined));
  } catch (error) {
    yield put(UserPreferencesActions.fetchUserPreferences.error(error as Error));
  }
}

export function* watchUserPreferencesSaga(
  action: ActionType<typeof UserPreferencesActions.watchUserPreferences>,
): Generator<unknown, void, any> {
  const { _id } = action.payload;
  const chan: EventChannel<any> = yield call(mongodbUserPreferencesWatchEmitter, _id);
  try {
    while (true) {
      const change = yield take(chan);

      if (change.operationType === 'insert' || change.operationType === 'update') {
        const fullDocument = Realm.BSON.EJSON.parse(
          JSON.stringify(change.fullDocument),
        ) as IMongodbUserPreferences;
        if (fullDocument._id === _id) {
          yield put(UserPreferencesActions.setUserPreferences(fullDocument));
        }
      }
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    chan.close();
  }
}

export function* updateTablePreferencesSaga(
  action: ActionType<typeof UserPreferencesActions.updateTablePreferences.saga>,
): Generator<unknown, void, any> {
  try {
    const { payload } = action;
    yield put(UserPreferencesActions.updateTablePreferences.start());

    yield call(updateUserPreferences, {
      filter: {
        _id: payload._id,
      },
      update: {
        $set: {
          [`screens.${[payload.screen]}.tables.${payload.table}.${payload.tableLevel}.${payload.preferencesType}`]:
            payload.data,
        },
      },
    });

    yield put(UserPreferencesActions.updateTablePreferences.success());
  } catch (error) {
    yield put(UserPreferencesActions.updateTablePreferences.error(error as Error));
  }
}

export function* updateAdminMenuPreferencesSaga(
  action: ActionType<typeof UserPreferencesActions.updateAdminMenuPreferences.saga>,
): Generator<unknown, void, any> {
  try {
    const { payload } = action;
    yield put(UserPreferencesActions.updateAdminMenuPreferences.start());

    yield call(updateUserPreferences, {
      filter: {
        _id: payload._id,
      },
      update: {
        $set: {
          adminMenuCollapsed: payload.adminMenuCollapsed,
        },
      },
    });

    yield put(UserPreferencesActions.updateAdminMenuPreferences.success());
  } catch (error) {
    yield put(UserPreferencesActions.updateAdminMenuPreferences.error(error as Error));
  }
}

function* fetchUserPreferencesSagaListener() {
  yield takeLatest(
    UserPreferencesActions.fetchUserPreferences.saga.toString(),
    fetchUserPreferencesSaga,
  );
}

function* updateTablePreferencesSagaListener() {
  yield takeEvery(
    UserPreferencesActions.updateTablePreferences.saga.toString(),
    updateTablePreferencesSaga,
  );
}

function* updateAdminMenuPreferencesSagaListener() {
  yield takeEvery(
    UserPreferencesActions.updateAdminMenuPreferences.saga.toString(),
    updateAdminMenuPreferencesSaga,
  );
}

function* watchUserPreferencesSagaListener() {
  yield takeLatest(
    UserPreferencesActions.watchUserPreferences.toString(),
    watchUserPreferencesSaga,
  );
}

export default function* userPreferencesSaga() {
  yield fork(updateAdminMenuPreferencesSagaListener);
  yield fork(fetchUserPreferencesSagaListener);
  yield fork(updateTablePreferencesSagaListener);
  yield fork(watchUserPreferencesSagaListener);
}
