import type {
  IContentfulSys,
  IContentfulRichText,
  IContentfulAsset,
  IContentfulCollection,
  IContentfulGeo,
} from '.';

export enum EContentfulLocationStatus {
  Open = 'Open',
  Closed = 'Closed',
  OpeningSoon = 'Opening Soon',
  TemporarilyClosed = 'Temporarily Closed',
}

export interface IContentfulLocation {
  __typename: string;
  addressCity: string;
  addressCountry: string;
  addressState: string;
  addressStreet: string;
  addressZip: string;
  assistantManager?: string;
  description: IContentfulRichText;
  distance?: number;
  email?: string;
  geo: IContentfulGeo;
  handle: string;
  locationCode: string;
  locationPos: number;
  locationType: string;
  manager?: string;
  mediaCollection: IContentfulCollection<IContentfulAsset>;
  openingDate: string;
  phone: string;
  previewMedia: IContentfulAsset;
  previewText: IContentfulRichText;
  status: `${EContentfulLocationStatus}`;
  storeHours: IContentfulStoreHours;
  sys: IContentfulSys;
  title: string;
  shopifyId?: string;
  s2KCompanyNo: string;
  s2KLocationType: string;
  chargeStateTax: string;
  chargeCntyTax: string;
  chargeLoc1Tax: string;
  countyCode: string;
  taxPercentage: number;
  local1: string;
  taxPctSafetyTax: number;
  timeZoneId?: string;
  curbsidePhoneNumber?: string;
}

type IContentfulStoreHoursDay =
  | 'everyday'
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export type IContentfulStoreHours = {
  [key in IContentfulStoreHoursDay]?: {
    isOpen?: boolean;
    openTime?: {
      time: string;
    };
    closeTime?: {
      time: string;
    };
  };
} & {
  beginDate?: string;
  selectedId?: 'everyday' | 'specific' | '';
};
