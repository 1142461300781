import React, { useRef } from 'react';
import { IWaiverFormProps, WaiverForm } from './WaiverForm';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';
import { PrinterIcon } from '../Icons';

const ComponentToPrint = React.forwardRef<HTMLDivElement, React.PropsWithChildren>((props, ref) => {
  return <div ref={ref}>{props.children}</div>;
});

interface IWaiverFormPrintProps {
  className?: string;
  addressFormProps?: IWaiverFormProps['addressFormProps'];
  signatureFormProps?: IWaiverFormProps['signatureFormProps'];
  contents: IWaiverFormProps['contents'];
  showForm?: boolean;
  children?: (handlePrint: ReturnType<typeof useReactToPrint>) => React.ReactNode;
  documentTitle?: string;
}

const defaultAddressFormProps = {
  name: '',
  address: '',
  zip: '',
  phone: '',
  state: '',
  city: '',
  dob: '',
  stateId: {
    id: '',
    state: '',
  },
};

const defaultSignatureFormProps = {
  customer: '',
  date: '',
  time: '',
  source: '',
};

export const WaiverFormPrint = ({
  addressFormProps = defaultAddressFormProps,
  signatureFormProps = defaultSignatureFormProps,
  contents,
  className,
  children,
  showForm,
  documentTitle = 'Waiver Form',
}: IWaiverFormPrintProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    documentTitle,
    content: () => componentRef.current,
    pageStyle: 'padding:24px',
  });
  return (
    <div>
      <div
        style={{
          display: showForm ? undefined : 'none',
        }}
      >
        <ComponentToPrint ref={componentRef}>
          <WaiverForm
            addressFormProps={addressFormProps}
            signatureFormProps={signatureFormProps}
            contents={contents}
          />
        </ComponentToPrint>
      </div>

      {children ? (
        children(handlePrint)
      ) : (
        <Button
          className=" flex justify-center items-center"
          icon={<PrinterIcon />}
          onClick={handlePrint}
        ></Button>
      )}
    </div>
  );
};
