import { Flex } from 'antd';
import React from 'react';
import { PFLogo } from '../PFLogo';
import { UserAction, IUserDropdownProps } from './UserAction';
import { Link } from 'react-router-dom';

interface IBaseDesktopHeaderProps {
  centerHeaderItem?: React.ReactNode;
  rightHeaderItems?: React.ReactNode[];
  userActionItems?: IUserDropdownProps['items'];
  onUserActionItemClick?: IUserDropdownProps['onClick'];
}

export const BaseMobileHeader = ({
  centerHeaderItem = [null],
  userActionItems = [],
  onUserActionItemClick,
}: IBaseDesktopHeaderProps) => {
  return (
    <div className="border-solid border-0 border-b-2 border-colorPrimary print:!hidden">
      <Flex align="center" justify="space-between" className="px-2 py-2 md:px-4" gap={20}>
        <Link to={'/'}>
          <PFLogo width={54} height={54} />
        </Link>
        {centerHeaderItem}
        <div>
          <UserAction items={userActionItems} onClick={onUserActionItemClick} />
        </div>
      </Flex>
    </div>
  );
};
