import { getCollection, realmApp } from '../../realm';
import * as Realm from 'realm-web';
import {
  IMongodbUser,
  EMongoDbFunction,
  EMongoDbCollection,
  IMongodbStore,
} from '../../types/mongodb';
import { IOneLoginTokenData } from '../../types';
import { eventChannel } from 'redux-saga';

export const getMongodbUserLatestData = async () => {
  try {
    if (realmApp?.currentUser && realmApp?.currentUser.isLoggedIn) {
      await realmApp.currentUser.refreshCustomData();
      if (realmApp?.currentUser.isLoggedIn) {
        return realmApp?.currentUser.customData as IMongodbUser;
      }
    }
  } catch (e) {
    throw e;
  }
};

export const logoutAccount = async () => {
  try {
    const currentUser = realmApp.currentUser;
    if (currentUser) {
      await currentUser.logOut();
      await realmApp.removeUser(currentUser);
    }
  } catch (e) {
    throw e;
  }
};

export interface ILoginAccountPayload {
  code: string;
}

export const loginAccount = async ({ code }: ILoginAccountPayload) => {
  try {
    const res = await fetch('/oauth/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
      }),
    });
    const data = (await res.json()) as IOneLoginTokenData;
    const credentials = Realm.Credentials.jwt(data.id_token);
    const user = await realmApp.logIn(credentials);
    await user.functions.callFunction(EMongoDbFunction.upsertUserDocsOnLogin);
    return await getMongodbUserLatestData();
  } catch (e) {
    await logoutAccount();
    throw e;
  }
};

export const mongodbCurrentUserWatchEmitter = async (currentUser: IMongodbUser) => {
  return eventChannel(emitter => {
    const usersCollection = getCollection<IMongodbStore>(EMongoDbCollection.users);
    const changeStream = usersCollection.watch({
      filter: {
        'fullDocument.email': currentUser.email,
      },
    });
    const watchData = async () => {
      try {
        for await (const change of changeStream) {
          emitter(change);
        }
      } catch (e) {
        throw e;
      }
    };
    watchData();
    return () => {
      changeStream.return('closed');
    };
  });
};
