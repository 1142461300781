import { IInventory } from '../../types/fulfillments/Inventory';
import { actionCreator, asyncActionCreator } from '../helper/index';

enum InventoryActionTypes {
  GET_PRODUCTS_INVENTORIES = 'INVENTORY/GET_PRODUCTS_INVENTORIES',
  SET_PRODUCTS_INVENTORIES = 'INVENTORY/SET_PRODUCTS_INVENTORIES',
}

export const getProductsInventories = asyncActionCreator<
  Map<string, IInventory> | undefined,
  { locationCode: string; productsSku: string[] }
>(InventoryActionTypes.GET_PRODUCTS_INVENTORIES);
export const setProductsInventories = actionCreator<{ inventories: IInventory[] }>(
  InventoryActionTypes.GET_PRODUCTS_INVENTORIES,
);
