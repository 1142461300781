import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
  ButtonProps,
  FormInstance,
} from 'antd';
import { ClearIcon, TaskIcon } from '../../Icons';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { IStoreBopisSettings } from '../../../types/mongodb';
import { IStore } from '../../../types';
import { useEffect } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectCompanySettings } from '../../../redux/selectors/companySelector';
import {
  BopisSettingsEarliestPickupDay,
  BopisSettingsPickupBy,
  BopisSettingsEarliestPickupTimeFormat,
  BopisSettingsPickupSlotDuration,
  BopisSettingsUsuallyReadyIn,
  EBopisSettingsPickupBy,
} from '../../../types/mongodb';

export interface IStoreBopisSettingsFormData
  extends Omit<IStoreBopisSettings, 'bopisEndDate' | 'bopisBeginDate' | 'earliestPickupTime'> {
  bopisEndDate?: dayjs.Dayjs;
  bopisBeginDate?: dayjs.Dayjs;
  earliestPickupTime?: dayjs.Dayjs;
}
export interface IStoreBopisSettingsProps {
  className?: string;
  onCancel?: (form: FormInstance<IStoreBopisSettingsFormData>) => void;
  onApply?: (values: IStoreBopisSettingsFormData) => void;
  loading?: boolean;
  store: IStore;
  hideCompanyInfo?: boolean;
}

export const StoreBopisSettings = ({
  className,
  onCancel,
  onApply,
  loading,
  store,
  hideCompanyInfo,
}: IStoreBopisSettingsProps) => {
  const companySettings = useAppSelector(selectCompanySettings);
  const [form] = Form.useForm<IStoreBopisSettingsFormData>();
  const formPickupByValue = Form.useWatch('pickupBy', form);
  const formBopisEndDate = Form.useWatch('bopisEndDate', form);
  const formBopisBeginDate = Form.useWatch('bopisBeginDate', form);
  const makeItemUnavailableWhenInventoryBelow = Form.useWatch(
    'makeItemUnavailableWhenInventoryBelow',
    form,
  );
  const markInventoryLowBelow = Form.useWatch('markInventoryLowBelow', form);
  const initialFormValues = {
    ...store.bopisSettings,
    earliestPickupTime: store.bopisSettings?.earliestPickupTime
      ? dayjs(store.bopisSettings?.earliestPickupTime, BopisSettingsEarliestPickupTimeFormat)
      : undefined,
    bopisBeginDate: store.bopisSettings?.bopisBeginDate
      ? dayjs(store.bopisSettings?.bopisBeginDate)
      : undefined,
    bopisEndDate: store.bopisSettings?.bopisEndDate
      ? dayjs(store.bopisSettings?.bopisEndDate)
      : undefined,
  };
  const handelApplyClick = () => {
    form.submit();
  };

  const handleCancelClick = () => {
    onCancel?.(form);
  };

  const handelFormFinish = (values: IStoreBopisSettingsFormData) => {
    onApply?.(values);
  };

  // useEffect(() => {
  //   console.log('form store', store);
  // }, [store]);
  return (
    <div className={clsx(className)}>
      {!hideCompanyInfo && (
        <div className=" flex flex-wrap justify-between items-center mb-3">
          <Space>
            <Typography.Text>Company BOPIS:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {companySettings?.bopisStatus || ''}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>Begin Date:</Typography.Text>
            <Typography.Text type="secondary">
              {companySettings?.bopisBeginDate
                ? dayjs(companySettings.bopisBeginDate).format('MM/DD/YYYY')
                : '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>End Date:</Typography.Text>
            <Typography.Text type="secondary">
              {companySettings?.bopisEndDate
                ? dayjs(companySettings.bopisEndDate).format('MM/DD/YYYY')
                : '-'}
            </Typography.Text>
          </Space>
        </div>
      )}

      <Form<IStoreBopisSettingsFormData>
        layout="vertical"
        form={form}
        onFinish={handelFormFinish}
        disabled={loading}
        initialValues={initialFormValues}
      >
        <Row gutter={[16, 8]}>
          <Col>
            <Form.Item label="Begin Date" name={'bopisBeginDate'}>
              <DatePicker
                minDate={
                  companySettings?.bopisBeginDate &&
                  dayjs(companySettings?.bopisBeginDate).isAfter(dayjs())
                    ? dayjs(companySettings?.bopisBeginDate)
                    : dayjs()
                }
                maxDate={formBopisEndDate ? formBopisEndDate.subtract(1, 'day') : undefined}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="End Date" name={'bopisEndDate'}>
              <DatePicker
                minDate={
                  formBopisBeginDate ? formBopisBeginDate.add(1, 'day') : dayjs().add(1, 'day')
                }
                // maxDate={
                //   companySettings?.bopisEndDate ? dayjs(companySettings?.bopisEndDate) : undefined
                // }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col>
            <Form.Item label="Pickup by Schedule / Earliest" name="pickupBy">
              <Select popupMatchSelectWidth={false} allowClear>
                {BopisSettingsPickupBy.map(option => (
                  <Select.Option value={option}>{option}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Usually Ready In" name="usuallyReadyIn">
              <Select popupMatchSelectWidth={false} allowClear>
                {BopisSettingsUsuallyReadyIn.map(value => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {formPickupByValue === EBopisSettingsPickupBy.Enum['When Ready'] ? (
            <></>
          ) : (
            <>
              <Col>
                <Form.Item label="Earliest Pickup Day" name="earliestPickupDay">
                  <Select popupMatchSelectWidth={false} allowClear>
                    {BopisSettingsEarliestPickupDay.map(value => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Earliest Pickup Time" name="earliestPickupTime">
                  <TimePicker use12Hours format={BopisSettingsEarliestPickupTimeFormat} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Pickup Slot Duration" name="pickupSlotDuration">
                  <Select popupMatchSelectWidth={false} allowClear>
                    {BopisSettingsPickupSlotDuration.map(value => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={[16, 8]}>
          <Col>
            <Form.Item label="Mark inventory low below" name="markInventoryLowBelow">
              <InputNumber min={makeItemUnavailableWhenInventoryBelow ?? 0} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Make Item Unavailable when inventory below"
              name="makeItemUnavailableWhenInventoryBelow"
            >
              <InputNumber min={0} max={markInventoryLowBelow} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className=" flex justify-end items-center gap-2">
        <Button
          shape="round"
          icon={<ClearIcon />}
          className="flex items-center"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          shape="round"
          type="primary"
          icon={<TaskIcon />}
          className="flex items-center"
          onClick={handelApplyClick}
          loading={loading}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
