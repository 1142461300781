import { actionCreator, asyncActionCreator } from '../../redux/helper';
import { EScreenNames } from '../../types';
import { IMongodbUserPreferences } from '../../types/mongodb/TypeUserPreferences';
import { ETableNames, IColumnState, ITableLevel } from '../../types/table';

enum UserPreferencesActionType {
  FETCH_USER_PREFERENCES = 'USER_PREFERENCES/FETCH_USER_PREFERENCES',
  UPDATE_TABLE_PREFERENCES = 'USER_PREFERENCES/UPDATE_TABLE_PREFERENCES',
  UPDATE_ADMIN_MENU_PREFERENCES = 'USER_PREFERENCES/UPDATE_ADMIN_MENU_PREFERENCES',
  SET_USER_PREFERENCES = 'USER_PREFERENCES/SET_USER_PREFERENCES',
  WATCH_USER_PREFERENCES = 'USER_PREFERENCES/WATCH_USER_PREFERENCES',
}

export const fetchUserPreferences = asyncActionCreator<
  IMongodbUserPreferences | undefined,
  { _id: string }
>(UserPreferencesActionType.FETCH_USER_PREFERENCES);

type IUpdateTablePreferencesSagaPayload = {
  _id: string;
  screen: EScreenNames;
  table: ETableNames;
  tableLevel: ITableLevel;
} & (
  | {
      preferencesType: 'column';
      data: IColumnState[];
    }
  | {
      preferencesType: 'filter';
      data: Record<string, any>;
    }
);
export const updateTablePreferences = asyncActionCreator<
  undefined,
  IUpdateTablePreferencesSagaPayload
>(UserPreferencesActionType.UPDATE_TABLE_PREFERENCES);

export const updateAdminMenuPreferences = asyncActionCreator<
  undefined,
  { _id: string; adminMenuCollapsed: boolean }
>(UserPreferencesActionType.UPDATE_ADMIN_MENU_PREFERENCES);

export const setUserPreferences = actionCreator<IMongodbUserPreferences>(
  UserPreferencesActionType.SET_USER_PREFERENCES,
);

export const watchUserPreferences = actionCreator<{
  _id: string;
}>(UserPreferencesActionType.WATCH_USER_PREFERENCES);
