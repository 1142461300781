import { IInventory } from '../../types/fulfillments/Inventory';
import { callPageFunction } from '../../utils';

export const getProductsInventories = async (locationsCode: string[], productsSku: string[]) => {
  try {
    const data = await callPageFunction(
      `/api/private/inventory/inventory?locationsCode=${JSON.stringify(locationsCode)}&productsSku=${JSON.stringify(productsSku)}`,
    );
    return (data?.data || []) as IInventory[];
  } catch (err) {
    throw err;
  }
};
