import { fork, put, call, takeLeading, select, take } from 'redux-saga/effects';
import { AuthActions, CommonActions } from '../../redux/actions';
import {
  getMongodbUserLatestData,
  loginAccount,
  logoutAccount,
  mongodbCurrentUserWatchEmitter,
} from '../../redux/services/authService';
import { ActionType } from 'typesafe-actions';
import { selectCurrentUser } from '../selectors/authSelector';
import { IMongodbUser } from '../../types/mongodb';
import { EventChannel } from 'redux-saga';
import * as Realm from 'realm-web';

export function* validateUserSaga(): Generator<unknown, void, any> {
  try {
    yield put(AuthActions.validateUser.start());
    const currentUser: Awaited<ReturnType<typeof getMongodbUserLatestData>> =
      yield call(getMongodbUserLatestData);
    if (currentUser) {
      yield put(AuthActions.setCurrentUser(currentUser));
    }
    yield put(AuthActions.validateUser.success());
  } catch (error) {
    AuthActions.validateUser.error(error as Error);
  }
}

export function* loginAccountSaga(
  action: ActionType<typeof AuthActions.loginAccount.saga>,
): Generator<unknown, void, any> {
  try {
    yield put(AuthActions.loginAccount.start());
    const currentUser: Awaited<ReturnType<typeof loginAccount>> = yield call(
      loginAccount,
      action.payload,
    );
    if (currentUser) {
      yield put(AuthActions.setCurrentUser(currentUser));
    }
    yield put(AuthActions.loginAccount.success());
  } catch (error) {
    yield put(AuthActions.loginAccount.error(error as Error));
  }
}

export function* logoutAccountSaga(): Generator<unknown, void, any> {
  try {
    yield put(AuthActions.logoutAccount.start());
    yield put(logoutAccount);
    yield put(AuthActions.logoutAccount.success());
    yield put(CommonActions.clearReduxStore());
    window.location.href = `https://${process.env.REACT_APP_ONE_LOGIN_SUBDOMAIN}.onelogin.com/oidc/2/logout`;
  } catch (error) {
    yield put(AuthActions.loginAccount.error(error as Error));
  }
}
export function* watchCurrentUserSaga(): Generator<unknown, void, any> {
  const currentUser: IMongodbUser = yield select(selectCurrentUser);
  const chan: EventChannel<any> = yield call(mongodbCurrentUserWatchEmitter, currentUser);
  yield put(AuthActions.watchCurrentUser.start());
  try {
    while (true) {
      const change = yield take(chan);
      if (change.operationType === 'update') {
        const _fullDocument = change.fullDocument;
        if (_fullDocument?.email === currentUser.email) {
          const fullDocument = Realm.BSON.EJSON.parse(
            JSON.stringify(change.fullDocument),
          ) as IMongodbUser;
          yield put(AuthActions.setCurrentUser(fullDocument));
        }
      }
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    chan.close();
  }
}

function* validateUserListener() {
  yield takeLeading(AuthActions.validateUser.saga.toString(), validateUserSaga);
}

function* loginAccountListener() {
  yield takeLeading(AuthActions.loginAccount.saga.toString(), loginAccountSaga);
}

function* logoutAccountListener() {
  yield takeLeading(AuthActions.logoutAccount.saga.toString(), logoutAccountSaga);
}

function* watchCurrentUserListener() {
  yield takeLeading(AuthActions.watchCurrentUser.saga.toString(), watchCurrentUserSaga);
}

export default function* authSaga() {
  yield fork(validateUserListener);
  yield fork(loginAccountListener);
  yield fork(logoutAccountListener);
  yield fork(watchCurrentUserListener);
}
