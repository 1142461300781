import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { orderReducer } from './orderReducer';
import { userReducer } from './userReducer';
import { storeReducer } from './storeReducer';
import { productReducer } from './productReducer';
import { companyReducer } from './companyReducer';
import { inventoryReducer } from './inventoryReducer';
import { notificationReducer } from './notificationReducer';
import { userPreferencesReducer } from './userPreferencesReducer';

export const rootReducer = combineReducers({
  Auth: authReducer,
  Order: orderReducer,
  User: userReducer,
  Store: storeReducer,
  Product: productReducer,
  Company: companyReducer,
  Inventory: inventoryReducer,
  Notification: notificationReducer,
  UserPreferences: userPreferencesReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
