import { Flex } from 'antd';
import React from 'react';
import { PFLogo } from '../PFLogo';
import { UserAction, IUserDropdownProps } from './UserAction';
import { Link } from 'react-router-dom';

interface IBaseDesktopHeaderProps {
  leftHeaderItems?: React.ReactNode[];
  rightHeaderItems?: React.ReactNode[];
  userActionItems?: IUserDropdownProps['items'];
  onUserActionItemClick?: IUserDropdownProps['onClick'];
}

export const BaseDesktopHeader = ({
  leftHeaderItems = [null],
  rightHeaderItems = [null],
  userActionItems = [],
  onUserActionItemClick,
}: IBaseDesktopHeaderProps) => {
  return (
    <div className="border-solid border-0 border-b-2 border-colorPrimary print:!hidden">
      <Flex align="center" justify="space-between" className="px-4 py-2">
        <Flex justify="flex-start" align="center" gap={12} className=" basis-1/2">
          <Link to="/">
            <PFLogo width={64} height={64} />
          </Link>

          {leftHeaderItems}
        </Flex>
        <Flex justify="flex-end" align="center" gap={12} className=" basis-1/2">
          {rightHeaderItems}
          <UserAction items={userActionItems} onClick={onUserActionItemClick} />
        </Flex>
      </Flex>
    </div>
  );
};
