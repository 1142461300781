import { MenuProps } from 'antd';
import {
  SettingsIcon,
  OrderIcon,
  ProductsIcon,
  UserIcon,
  StoreIcon,
  AnalyzeIcon,
  CompanyIcon,
} from '../../Icons';
import { MenuItemLabel } from './MenuItemLabel';
import { RouteNames } from '../../../types';
import { logEvent } from '../../../utils/EventLogger';
import { EMongodbEventType } from '../../../types/mongodb';

interface IGetMenuItemsOption {
  isMenuCollapsed: boolean;
}

export const getMenuItems = ({ isMenuCollapsed = false }: IGetMenuItemsOption) => {
  const items: MenuProps['items'] = [
    {
      key: RouteNames.AdminSettings,
      label: (
        <MenuItemLabel
          icon={<SettingsIcon size={20} />}
          title={'Settings'}
          isMenuCollapsed={isMenuCollapsed}
        />
      ),

      children: [
        {
          key: RouteNames.AdminSettingsCompany,
          label: (
            <MenuItemLabel
              to={RouteNames.AdminSettingsCompany}
              icon={<CompanyIcon size={20} />}
              title={'Company'}
              isMenuCollapsed={isMenuCollapsed}
            />
          ),
          onClick: () => {
            logEvent({
              type: EMongodbEventType.Enum['bopis.admin.side-menu.click-company-settings'],
            });
          },
        },
        {
          key: RouteNames.AdminSettingsProducts,
          label: (
            <MenuItemLabel
              to={RouteNames.AdminSettingsProducts}
              icon={<ProductsIcon size={20} />}
              title={'Products'}
              isMenuCollapsed={isMenuCollapsed}
            />
          ),
          onClick: () => {
            logEvent({ type: EMongodbEventType.Enum['bopis.admin.side-menu.click-products'] });
          },
        },
        {
          key: RouteNames.AdminSettingsStores,
          label: (
            <MenuItemLabel
              icon={<StoreIcon size={20} />}
              title={'Stores'}
              to={RouteNames.AdminSettingsStores}
              isMenuCollapsed={isMenuCollapsed}
            />
          ),
          onClick: () => {
            logEvent({ type: EMongodbEventType.Enum['bopis.admin.side-menu.click-stores'] });
          },
        },
        {
          key: RouteNames.AdminSettingsUsers,
          label: (
            <MenuItemLabel
              icon={<UserIcon size={20} />}
              title={'Users'}
              to={RouteNames.AdminSettingsUsers}
              isMenuCollapsed={isMenuCollapsed}
            />
          ),
          onClick: () => {
            logEvent({ type: EMongodbEventType.Enum['bopis.admin.side-menu.click-users'] });
          },
        },
      ],
    },
    // {
    //   key: RouteNames.AdminAnalyze,
    //   label: (
    //     <MenuItemLabel
    //       icon={<AnalyzeIcon size={20} />}
    //       title={'Analyze'}
    //       isMenuCollapsed={isMenuCollapsed}
    //     />
    //   ),

    //   children: [
    //     {
    //       key: RouteNames.AdminAnalyzeOrders,
    //       label: (
    //         <MenuItemLabel
    //           icon={<OrderIcon size={20} />}
    //           title={'Orders'}
    //           to={RouteNames.AdminAnalyzeOrders}
    //           isMenuCollapsed={isMenuCollapsed}
    //         />
    //       ),
    //     },
    //     {
    //       key: RouteNames.AdminAnalyzeProducts,

    //       label: (
    //         <MenuItemLabel
    //           to={RouteNames.AdminAnalyzeProducts}
    //           icon={<ProductsIcon size={20} />}
    //           title={'Products'}
    //           isMenuCollapsed={isMenuCollapsed}
    //         />
    //       ),
    //     },
    //     {
    //       key: RouteNames.AdminAnalyzeStores,
    //       label: (
    //         <MenuItemLabel
    //           icon={<StoreIcon size={20} />}
    //           title={'Stores'}
    //           to={RouteNames.AdminAnalyzeStores}
    //           isMenuCollapsed={isMenuCollapsed}
    //         />
    //       ),
    //     },
    //     {
    //       key: RouteNames.AdminAnalyzeUsers,
    //       label: (
    //         <MenuItemLabel
    //           icon={<UserIcon size={20} />}
    //           title={'Users'}
    //           to={RouteNames.AdminAnalyzeUsers}
    //           isMenuCollapsed={isMenuCollapsed}
    //         />
    //       ),
    //     },
    //   ],
    // },
  ];

  return items;
};
