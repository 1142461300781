import { Flex, Tag, Typography } from 'antd';
import dayjs from 'dayjs';

interface ISearchPill {
  label: string;
  value: string;
  onCancel: () => void;
}
/**
 * Renders a pill component with a label and value. The label is converted to title case.
 *
 * @param {ISearchPill} props - The props object containing the label, value, and onCancel function.
 * If you have a date in your value, it must have a date include in label string e.g. 'xyzDate' .
 * @return {JSX.Element} The rendered pill component.
 */
export function Pill(props: ISearchPill): JSX.Element {
  const { label, value, onCancel } = props;
  const { Text } = Typography;

  const titleCaseLabel = (pillLabel: string) => {
    let spacedStr = pillLabel.replace(/([A-Z])/g, ' $1');
    spacedStr = spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);
    return spacedStr;
  };

  return (
    <Tag closeIcon onClose={() => onCancel()} className="flex gap-1 rounded-full py-1 px-2">
      <Text className="block w-max">{titleCaseLabel(label)}: </Text>
      <Flex gap={5}>
        <Text className="block w-max text-textSecondary">
          {label.includes('Date') ? dayjs(value)?.format('YYYY-MM-DD') : value}
        </Text>
      </Flex>
    </Tag>
  );
}
