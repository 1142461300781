import { createReducer } from '@reduxjs/toolkit';
import { asyncCaseCreator, InitialAsyncState } from '../helper';
import { InventoryActions } from '../actions';
import { EReduxAsyncState } from '../../types/redux';
import { IInventory } from '../../types/fulfillments/Inventory';

interface IInventoryState {
  productsInventories: InitialAsyncState<Map<string, IInventory>>;
}

const defaultCompanyState: IInventoryState = {
  productsInventories: { status: EReduxAsyncState.idle, data: new Map() },
};

export const inventoryReducer = createReducer(defaultCompanyState, builder => {
  asyncCaseCreator(InventoryActions.getProductsInventories, 'productsInventories', builder);
  builder.addCase(InventoryActions.setProductsInventories, (state, action) => {
    const { inventories } = action.payload;
    const newInventories: Map<string, IInventory> = new Map(state.productsInventories.data);
    inventories.forEach(inventory => {
      newInventories.set(inventory.product, inventory);
    });
    state.productsInventories.data = newInventories;
  });
});
