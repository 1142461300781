import { useEffect, useMemo, useState } from 'react';
import Quill from 'quill';
import Delta, { Op } from 'quill-delta';
import { WaiverSignatureForm, IWaiverSignatureFormProps } from './WaiverSignatureForm';
import { WaiverAddressForm, IWaiverAddressFormProps } from './WaiverAddressForm';
import { renderToStaticMarkup } from 'react-dom/server';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export interface IWaiverFormProps {
  addressFormProps: IWaiverAddressFormProps;
  signatureFormProps: IWaiverSignatureFormProps;
  contents: Delta | Op[];
}

export const WaiverForm = ({
  addressFormProps,
  signatureFormProps,
  contents,
}: IWaiverFormProps) => {
  const [_waiverFormHtml, setWaiverFormHtml] = useState('');

  const waiverFormHtml = useMemo(() => {
    return _waiverFormHtml
      .replaceAll(
        '<p class="ql-align-center">{{Address Form}}</p>',
        renderToStaticMarkup(<WaiverAddressForm {...addressFormProps} />),
      )
      .replaceAll(
        '<p>{{Address Form}}</p>',
        renderToStaticMarkup(<WaiverAddressForm {...addressFormProps} />),
      )
      .replaceAll(
        '<p class="ql-align-center">{{Signature Form}}</p>',
        renderToStaticMarkup(<WaiverSignatureForm {...signatureFormProps} />),
      )
      .replaceAll(
        '<p>{{Signature Form}}</p>',
        renderToStaticMarkup(<WaiverSignatureForm {...signatureFormProps} />),
      );
  }, [_waiverFormHtml, addressFormProps, signatureFormProps]);

  useEffect(() => {
    if (contents) {
      const div = document.createElement('div');
      const quill = new Quill(div);
      quill.setContents(contents);
      setWaiverFormHtml(quill.getSemanticHTML());
    }
  }, [contents]);

  waiverFormHtml.replaceAll(
    '<p class="ql-align-center">{{Address Form}}</p>',
    renderToStaticMarkup(<WaiverAddressForm {...addressFormProps} />),
  );
  return (
    <div className="phantom-waiver-form ql-container ql-snow [&_p:empty]:h-[15px] !border-0 text-base">
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: waiverFormHtml,
        }}
      ></div>
    </div>
  );
};
