import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ProductActions } from '../../redux/actions';
import { EReduxAsyncState } from '../../types/redux';

export const useFetchProducts = (fetch = false) => {
  const dispatch = useAppDispatch();
  const products = useAppSelector(state => state.Product.products);

  useEffect(() => {
    if (!products.data?.length && products.status === EReduxAsyncState.idle && fetch) {
      dispatch(ProductActions.fetchProducts.saga());
    }
  }, []);

  return products;
};
