import * as Realm from 'realm-web';
import { EMongoDbCollection } from './types/mongodb';
import atlasConfigDev from './atlasConfig.dev.json';
import atlasConfigStage from './atlasConfig.stage.json';
import atlasConfigProd from './atlasConfig.prod.json';

const atlasConfig =
  process.env.REACT_APP_WEBSITE_ENV === 'development'
    ? atlasConfigDev
    : process.env.REACT_APP_WEBSITE_ENV === 'stage'
      ? atlasConfigStage
      : atlasConfigProd;

export const realmApp = new Realm.App({ id: atlasConfig.appId, baseUrl: atlasConfig.baseUrl });

export function getCollection<T extends { _id: string } = any>(
  collection: `${EMongoDbCollection}`,
) {
  if (!realmApp.currentUser) {
    throw new Error('Login Error');
  }

  const mdb = realmApp.currentUser.mongoClient('mongodb-atlas');
  return mdb.db(atlasConfig.databaseName).collection<T>(collection);
}
