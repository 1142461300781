import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../helper';
import { EReduxAsyncState } from '../../types/redux';
import { OrdersActions } from '../actions';
import { cloneDeep } from 'lodash';
import { IFetchedOrdersForPages, IOrder, IStoreOrdersCount } from '../../types/fulfillments/Order';
import { getSimplifiedOrder } from '../../utils/order';
import { MongoDbOrderStatusEnum } from '../../types/mongodb';
interface IOrderState {
  orders: InitialAsyncState<Map<string, IOrder>>;
  watchOnOrders: InitialAsyncState;
  updateOrdersStatusHistory: InitialAsyncState<{ status?: MongoDbOrderStatusEnum }>;
  getOrder: InitialAsyncState<IOrder | undefined>;
  updateOrdersFulfillmentStatus: InitialAsyncState;
  markOrderReadyForPickup: InitialAsyncState;
  getSubstitutesProduct: InitialAsyncState;
  markOrderFulfilled: InitialAsyncState;
  getOrdersCount: InitialAsyncState;
  storeOrdersCount: InitialAsyncState<IStoreOrdersCount>;
  fetchedOrdersForPages: IFetchedOrdersForPages;
  findOrders: InitialAsyncState<{ orders: IOrder[]; count: number }>;
}

const defaultOrderState: IOrderState = {
  orders: { status: EReduxAsyncState.idle, data: new Map() },
  watchOnOrders: { status: EReduxAsyncState.idle },
  getOrder: { status: EReduxAsyncState.idle },
  updateOrdersStatusHistory: { status: EReduxAsyncState.idle },
  updateOrdersFulfillmentStatus: { status: EReduxAsyncState.idle },
  markOrderReadyForPickup: { status: EReduxAsyncState.idle },
  getSubstitutesProduct: { status: EReduxAsyncState.idle },
  markOrderFulfilled: { status: EReduxAsyncState.idle },
  getOrdersCount: { status: EReduxAsyncState.idle },
  storeOrdersCount: { status: EReduxAsyncState.idle, data: {} },
  findOrders: { status: EReduxAsyncState.idle, data: { orders: [], count: 0 } },
  fetchedOrdersForPages: {
    ALREADY_PICKED_UP: false,
    CANCELED: false,
    NEEDS_PACKING: false,
    READY_FOR_PICKUP: false,
  },
};

export const orderReducer = createReducer(defaultOrderState, builder => {
  asyncCaseCreator(OrdersActions.getOrdersForStore, 'orders', builder);
  asyncCaseCreator(OrdersActions.getOrder, 'getOrder', builder);
  asyncCaseCreator(OrdersActions.watchOnOrders, 'watchOnOrders', builder);
  asyncCaseCreator(OrdersActions.updateOrdersStatusHistory, 'updateOrdersStatusHistory', builder);
  asyncCaseCreator(OrdersActions.getSubstitutesProduct, 'getSubstitutesProduct', builder);
  asyncCaseCreator(OrdersActions.markOrderFulfilled, 'markOrderFulfilled', builder);
  asyncCaseCreator(OrdersActions.getOrdersCount, 'storeOrdersCount', builder);
  asyncCaseCreator(OrdersActions.findOrders, 'findOrders', builder);
  asyncCaseCreator(
    OrdersActions.updateOrdersFulfillmentStatus,
    'updateOrdersFulfillmentStatus',
    builder,
  );
  asyncCaseCreator(OrdersActions.markOrderReadyForPickup, 'markOrderReadyForPickup', builder);

  builder.addCase(OrdersActions.setOrder, (state, action) => {
    const { order } = action.payload;
    state.orders.data?.set(order._id, order);
  });
  builder.addCase(OrdersActions.resetOrders, (state, action) => {
    if (state.orders.data) {
      state.orders.data = new Map();
    }
    state.fetchedOrdersForPages = {
      ALREADY_PICKED_UP: false,
      CANCELED: false,
      NEEDS_PACKING: false,
      READY_FOR_PICKUP: false,
    };
  });
  builder.addCase(OrdersActions.updateFetchedOrdersForPages, (state, action) => {
    const { key, value } = action.payload;
    const prevInfo = cloneDeep(state.fetchedOrdersForPages);
    state.fetchedOrdersForPages = { ...prevInfo, [key]: value };
  });
  builder.addCase(OrdersActions.updateMongodbOrder, (state, action) => {
    const { mongodbOrder, shopifyOrder } = action.payload;
    const orderId = mongodbOrder?._id || shopifyOrder?.legacyResourceId;
    if ((shopifyOrder || mongodbOrder) && orderId) {
      const prevOrder = cloneDeep(state.orders.data?.get(orderId));
      if (mongodbOrder && shopifyOrder) {
        const simplifiedOrder = getSimplifiedOrder(mongodbOrder, shopifyOrder);
        state.orders.data?.set(orderId, simplifiedOrder);
      } else if (mongodbOrder && prevOrder) {
        const simplifiedOrder = getSimplifiedOrder(mongodbOrder, prevOrder.shopifyOrder);
        state.orders.data?.set(orderId, simplifiedOrder);
      } else if (shopifyOrder && prevOrder) {
        const simplifiedOrder = getSimplifiedOrder(prevOrder, shopifyOrder);
        state.orders.data?.set(orderId, simplifiedOrder);
      }
    }
  });
  builder.addCase(OrdersActions.removeOrder, (state, action) => {
    const { orderId } = action.payload;
    state.orders.data?.delete(orderId);
  });
  builder.addCase(OrdersActions.updateOrdersCount, (state, action) => {
    const { updatedFrom, updatedTo } = action.payload;
    if (updatedFrom && state.storeOrdersCount.data) {
      state.storeOrdersCount.data[updatedFrom] =
        (state.storeOrdersCount.data?.[updatedFrom] || 0) - 1;
    }
    if (state.storeOrdersCount.data && updatedTo) {
      state.storeOrdersCount.data[updatedTo] = (state.storeOrdersCount.data?.[updatedTo] || 0) + 1;
    }
  });
  builder.addCase(OrdersActions.setProductsSubstitutes, (state, action) => {
    const { orderId, productId, substitutesProducts } = action.payload;

    const _order = state.orders.data?.get(orderId);
    if (_order && state.orders.data) {
      const clonedOrder = cloneDeep(_order);
      const lineItems = clonedOrder.shopifyOrder.lineItems.nodes.map(item => {
        if (item.product.legacyResourceId === productId) {
          const fulfillmentStatus = _order.fulfillmentStatus?.find(
            mongodbOrder => mongodbOrder.productId === item.product.legacyResourceId,
          );
          return {
            ...item,
            substitutesProducts: substitutesProducts?.filter(
              sub => sub.legacyResourceId !== productId,
            ),
            substitutesProductsWithFilledQty: item.substitutesProducts?.map(subItem => {
              return {
                ...subItem,
                filledQty:
                  fulfillmentStatus?.substitutes?.find(
                    sub => sub.productId === subItem.legacyResourceId,
                  )?.filledQty || 0,
              };
            }),
          };
        }
        return item;
      });
      const newOrder = {
        ...clonedOrder,
        shopifyOrder: { ...clonedOrder.shopifyOrder, lineItems: { nodes: lineItems } },
      };
      state.orders.data.set(orderId, getSimplifiedOrder(newOrder, newOrder.shopifyOrder));
    }
  });
});
