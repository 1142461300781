import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { AppState } from '../reducers/rootReducer';
import groupBy from 'lodash/groupBy';
import { MongoDbOrderStatusEnum } from '../../types/mongodb';

const _selectMarkOrderReadyForPickup = (state: AppState) => state.Order.markOrderReadyForPickup;
export const selectMarkOrderReadyForPickup = createDraftSafeSelector(
  _selectMarkOrderReadyForPickup,
  payload => payload,
);

const _selectGetSubstitutesProduct = (state: AppState) => state.Order.getSubstitutesProduct;
export const selectGetSubstitutesProduct = createDraftSafeSelector(
  _selectGetSubstitutesProduct,
  payload => payload,
);

const _selectUpdateOrdersStatusHistory = (state: AppState) => state.Order.updateOrdersStatusHistory;
export const selectUpdateOrdersStatusHistory = createDraftSafeSelector(
  _selectUpdateOrdersStatusHistory,
  payload => payload,
);
const _selectUpdateOrdersFulfillmentStatus = (state: AppState) =>
  state.Order.updateOrdersFulfillmentStatus;
export const selectUpdateOrdersFulfillmentStatus = createDraftSafeSelector(
  _selectUpdateOrdersFulfillmentStatus,
  payload => payload,
);

const _selectMarkOrderFulfilled = (state: AppState) => state.Order.markOrderFulfilled;
export const selectMarkOrderFulfilled = createDraftSafeSelector(
  _selectMarkOrderFulfilled,
  payload => payload,
);

const _selectStoreOrdersCount = (state: AppState) => state.Order.storeOrdersCount;
export const selectStoreOrdersCount = createDraftSafeSelector(
  _selectStoreOrdersCount,
  payload => payload,
);

const _selectFetchedOrdersForPages = (state: AppState) => state.Order.fetchedOrdersForPages;
export const selectFetchedOrdersForPages = createDraftSafeSelector(
  _selectFetchedOrdersForPages,
  payload => payload,
);

const _selectOrders = (state: AppState) => state.Order.orders;
const _selectOrdersData = (state: AppState) => state.Order.orders.data;
export const selectOrders = createDraftSafeSelector(_selectOrders, payload => payload);
export const selectOrdersMap = createDraftSafeSelector(_selectOrdersData, payload => payload);
export const selectOrdersArray = createDraftSafeSelector(_selectOrdersData, payload =>
  payload ? Array.from(payload.values()) : [],
);
export const selectMongodbOrdersGroupByStatus = createDraftSafeSelector(
  _selectOrdersData,
  payload =>
    groupBy(Array.from((payload || new Map()).values()), order => {
      if (
        order.status === MongoDbOrderStatusEnum.NEEDS_PACKING ||
        order.status === MongoDbOrderStatusEnum.PACKING_STARTED ||
        order.status === MongoDbOrderStatusEnum.UNFULFILLABLE
      ) {
        return MongoDbOrderStatusEnum.NEEDS_PACKING;
      } else if (
        order.status === MongoDbOrderStatusEnum.READY_FOR_PICKUP ||
        order.status === MongoDbOrderStatusEnum.PAYMENT_CONFIRMED ||
        order.status === MongoDbOrderStatusEnum.PAYMENT_FAILED ||
        order.status === MongoDbOrderStatusEnum.ORDER_SUBSTITUTE_EDIT
      ) {
        return MongoDbOrderStatusEnum.READY_FOR_PICKUP;
      }
      return order.status;
    }),
);

const _selectGetOrder = (state: AppState) => state.Order.getOrder;
export const selectGetOrder = createDraftSafeSelector(_selectGetOrder, payload => payload);

const _selectFindOrders = (state: AppState) => state.Order.findOrders;
export const selectFindOrders = createDraftSafeSelector(_selectFindOrders, payload => payload);
