import {
  ColumnVisibleEvent,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  SortChangedEvent,
  AgGridEvent,
  FilterChangedEvent,
} from 'ag-grid-community';
import { ETableNames, ITableLevel } from '../types/table';
import { useAppDispatch } from '../redux/hooks';
import { EScreenNames } from '../types';
import { UserPreferencesActions } from '../redux/actions';

interface IUpdateUserPreferencesForTableOptions {
  event: AgGridEvent;
  _id: string;
  tableLevel: ITableLevel;
  screen: EScreenNames;
  table: ETableNames;
  dispatch: ReturnType<typeof useAppDispatch>;
}

export const updateUserPreferencesForTable = ({
  event,
  tableLevel,
  dispatch,
  _id,
  screen,
  table,
}: IUpdateUserPreferencesForTableOptions) => {
  switch (event.type) {
    case 'columnMoved':
    case 'columnResized': {
      const _event = event as ColumnMovedEvent | ColumnResizedEvent;
      if (_event.finished && _event.source !== 'api') {
        const columnState = event.api.getColumnState();
        dispatch(
          UserPreferencesActions.updateTablePreferences.saga({
            _id,
            screen,
            table,
            tableLevel,
            preferencesType: 'column',
            data: columnState,
          }),
        );
      }
      break;
    }

    case 'sortChanged':
    case 'columnPinned':
    case 'columnVisible': {
      const _event = event as SortChangedEvent | ColumnPinnedEvent | ColumnVisibleEvent;
      if (_event && _event.source !== 'api') {
        const columnState = event.api.getColumnState();
        dispatch(
          UserPreferencesActions.updateTablePreferences.saga({
            _id,
            screen,
            table,
            tableLevel,
            preferencesType: 'column',
            data: columnState,
          }),
        );
      }
      break;
    }

    case 'filterChanged': {
      const _event = event as FilterChangedEvent;
      if (_event && _event.source !== 'api') {
        const filterModel = event.api.getFilterModel();
        dispatch(
          UserPreferencesActions.updateTablePreferences.saga({
            _id,
            screen,
            table,
            tableLevel,
            preferencesType: 'filter',
            data: filterModel,
          }),
        );
      }
      break;
    }

    default:
      break;
  }
};
