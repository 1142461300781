import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { CompanyActions } from '../../redux/actions';

export const useFetchCompanySettings = (fetch = false) => {
  const dispatch = useAppDispatch();
  const companySettings = useAppSelector(state => state.Company.companySettings);

  useEffect(() => {
    if (!companySettings && fetch) {
      dispatch(CompanyActions.getCompanySettings.saga());
    }
  }, []);

  return companySettings;
};
