import { z } from 'zod';

export const StringToDateSchema = z.string().datetime().pipe(z.coerce.date());

export interface IOneLoginTokenData {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

export interface IRealmAccessTokenData {
  sub: string;
  exp: number;
  iat: number;
  iss: string;
  domain_id: string;
  device_id: string;
}

export enum EAppErrorCode {
  PAGE_FUNCTION_UNAUTHORIZED_ACCESS = 'PAGE_FUNCTION_UNAUTHORIZED_ACCESS',
  PAGE_FUNCTION_SERVER_ERROR = 'PAGE_FUNCTION_SERVER_ERROR',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  VERIFY_ONE_LOGIN_ERROR = 'VERIFY_ONE_LOGIN_ERROR',
  REALM_ACCESS_TOKEN_EXPIRED = 'REALM_ACCESS_TOKEN_EXPIRED',
  REALM_ACCESS_TOKEN_IS_INVALID = 'REALM_ACCESS_TOKEN_IS_INVALID',
  UNAUTHORIZED_PAYMENT_STATUS = 'UNAUTHORIZED_PAYMENT_STATUS',
}

export enum RouteNames {
  Login = '/login',
  Home = '/',
  Fulfillment = '/fulfillment',
  FulfillmentStore = '/fulfillment/:storeId',
  FulfillmentStoreReadyForPickup = '/fulfillment/:storeId/ready-for-pickup',
  FulfillmentStoreReadyForPickupDetails = '/fulfillment/:storeId/ready-for-pickup/:orderId',
  FulfillmentStoreNeedPacking = '/fulfillment/:storeId/need-packing',
  FulfillmentStoreNeedPackingOrderId = '/fulfillment/:storeId/need-packing/:orderId',
  FulfillmentStoreAlreadyPickedUp = '/fulfillment/:storeId/already-picked-up',
  FulfillmentStoreAlreadyPickedUpOrder = '/fulfillment/:storeId/already-picked-up/:orderId',
  FulfillmentStoreCancelledOrders = '/fulfillment/:storeId/canceled-orders',
  FulfillmentStoreCancelledOrderDetails = '/fulfillment/:storeId/canceled-orders/:orderId',
  FulfillmentStoreFindOrders = '/fulfillment/:storeId/find-orders',
  FulfillmentStoreNotifications = '/fulfillment/:storeId/notifications',
  Admin = '/admin',
  AdminSettings = '/admin/settings',
  AdminSettingsCompany = '/admin/settings/company',
  AdminSettingsProducts = '/admin/settings/products',
  AdminSettingsStores = '/admin/settings/stores',
  AdminSettingsStoresDetails = '/admin/settings/stores/:storeId',
  AdminSettingsUsers = '/admin/settings/users',
  AdminAnalyze = '/admin/analyze',
  AdminAnalyzeOrders = '/admin/analyze/orders',
  AdminAnalyzeProducts = '/admin/analyze/products',
  AdminAnalyzeStores = '/admin/analyze/stores',
  AdminAnalyzeUsers = '/admin/analyze/users',
}

export enum EScreenNames {
  Login = 'login',
  Home = 'home',
  Fulfillment = 'fulfillment',
  FulfillmentReadyForPickup = 'fulfillmentReadyForPickup',
  FulfillmentReadyForPickupDetails = 'fulfillmentReadyForPickupDetails',
  FulfillmentNeedPacking = 'fulfillmentNeedPacking',
  FulfillmentNeedPackingOrderId = 'fulfillmentNeedPackingOrderId',
  FulfillmentAlreadyPickedUp = 'fulfillmentAlreadyPickedUp',
  FulfillmentAlreadyPickedUpOrder = 'fulfillmentAlreadyPickedUpOrder',
  FulfillmentCancelledOrders = 'fulfillmentCancelledOrders',
  FulfillmentCancelledOrderDetails = 'fulfillmentCancelledOrderDetails',
  FulfillmentFindOrders = 'fulfillmentFindOrders',
  FulfillmentNotifications = 'fulfillmentNotifications',
  Admin = 'admin',
  AdminSettings = 'adminSettings',
  AdminSettingsCompany = 'adminSettingsCompany',
  AdminSettingsProducts = 'adminSettingsProducts',
  AdminSettingsStores = 'adminSettingsStores',
  AdminSettingsStoresDetails = 'adminSettingsStoresDetails',
  AdminSettingsUsers = 'adminSettingsUsers',
  AdminAnalyze = 'adminAnalyze',
  AdminAnalyzeOrders = 'adminAnalyzeOrders',
  AdminAnalyzeProducts = 'adminAnalyzeProducts',
  AdminAnalyzeStores = 'adminAnalyzeStores',
  AdminAnalyzeUsers = 'adminAnalyzeUsers',
}

export type IImage = {
  url: string;
};
export interface IRouteHandle {
  requiredOneLoginRoles?: string[];
  requiredStoreAccessRoles?: string[];
}

export type OptionType = {
  value: string;
  label: string;
};
