import type { FitBoundsOptions, LatLngBounds, LatLngExpression, LeafletEvent, Map } from 'leaflet';
import { LatLng, divIcon, latLngBounds } from 'leaflet';
import { useEffect, useMemo, useState, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { MapContainer, Marker, TileLayer, Tooltip, Popup } from 'react-leaflet';
import { IStore } from '../../types';
import { StorePosition } from '../Admin';
import { StoreCardBase } from '../Admin';

interface IPhantomMapProps {
  mapRef?: React.MutableRefObject<Map | null>;
  stores: IStore[];
  onViewChange?: (data: string[]) => void;
  onMarkerClicked?: (store: IStore) => void;
  centerMapTo?: { lat: number; lon: number };
  className?: string;
  style?: React.CSSProperties;
  center: [number, number];
}

const iconMarkup = (position: number) =>
  renderToStaticMarkup(
    <StorePosition
      className=" w-fit translate-x-[-11.5px] translate-y-[-25px] "
      position={position}
    />,
  );

const dotIconMarkup = () =>
  renderToStaticMarkup(
    <div className=" w-2 h-2 bg-blue-500 rounded-full shadow-[0px_0px_5px_2px]  shadow-blue-400"></div>,
  );

export function PhantomMap({
  mapRef,
  stores,
  onViewChange,
  onMarkerClicked,
  centerMapTo,
  className,
  style,
  center,
}: IPhantomMapProps) {
  const [map, setMap] = useState<Map | null>(null);

  const markers = useMemo(() => {
    return stores?.map(store => ({
      divIcon: divIcon({
        html: iconMarkup(store.locationPos),
        className: 'border-none bg-transparent',
        // iconAnchor: [35, 35],
      }),
      latLng: [store.geo.lat, store.geo.lon],
      tooltip: store.title,
      originalValue: store,
    }));
  }, [stores]);

  function showMarkerInView() {
    const inViewMarkers: string[] = [];

    markers.forEach(marker => {
      const isMarkerInView = map
        ?.getBounds()
        ?.contains({ lat: marker.latLng[0], lng: marker.latLng[1] });
      if (isMarkerInView) {
        inViewMarkers.push(marker.originalValue.sys.id);
      }
    });

    onViewChange?.(inViewMarkers);
  }

  useEffect(() => {
    if (!map) {
      return;
    }

    if (map) {
      if (centerMapTo?.lat && centerMapTo?.lon) {
        map.setView(
          {
            lat: centerMapTo?.lat,
            lng: centerMapTo?.lon,
          },
          7,
        );
      } else {
        map.setView(center, 3);
      }
    }
  }, [centerMapTo?.lat, centerMapTo?.lon]);

  function onMapMoveEnd(event: LeafletEvent) {
    if (!map) {
      return;
    }

    showMarkerInView();
  }

  function zoomChanged(event: LeafletEvent) {
    if (!map) {
      return;
    }

    showMarkerInView();
  }

  function onViewChanged(event: LeafletEvent) {
    if (!map) {
      return;
    }
    showMarkerInView();
  }

  useEffect(() => {
    if (!map) return;
    map.addEventListener('viewreset', onViewChanged);
    return () => {
      map.removeEventListener('viewreset', onViewChanged);
    };
  }, [map, markers]);

  useEffect(() => {
    if (!map) return;
    map.addEventListener('dragend', onMapMoveEnd);
    return () => {
      map.removeEventListener('dragend', onMapMoveEnd);
    };
  }, [map, markers]);

  useEffect(() => {
    if (!map) return;
    map.addEventListener('zoomend', zoomChanged);

    return () => {
      map.removeEventListener('zoomend', zoomChanged);
    };
  }, [map, markers]);

  useEffect(() => {
    if (map && mapRef) {
      mapRef.current = map;
    }
  }, [map, mapRef]);

  return (
    <MapContainer
      ref={setMap}
      className={className}
      style={style}
      zoom={3}
      scrollWheelZoom={false}
      center={center}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <Marker
        icon={divIcon({
          html: dotIconMarkup(),
          className: 'border-none bg-transparent',
        })}
        position={centerMapTo ? [centerMapTo?.lat, centerMapTo?.lon] : center}
      ></Marker>

      {markers?.map((location, index) => {
        return (
          <Marker
            key={index.toString()}
            icon={location.divIcon}
            position={location.latLng as LatLngExpression}
            eventHandlers={{
              click: e => {
                onMarkerClicked?.(location.originalValue);
              },
            }}
          >
            <Tooltip className="rounded-sm z-[10000]">
              <LocationTooltip store={location.originalValue} />
            </Tooltip>
          </Marker>
        );
      })}
    </MapContainer>
  );
}

function LocationTooltip({ store }: { store: IStore }) {
  return (
    <div>
      <span className="font-bold">{store.title}</span>

      <div>
        <div>{store.sys.id}</div>
        <div>{store.addressStreet}</div>
        <div>
          <span>{store?.addressCity}</span>
          <span>{','}</span>
          <span className="ml-0.5">{store.addressState}</span>
          <span className="ml-0.5">{store.addressZip}</span>
        </div>
      </div>
      <div>{store.phone && <span>{store.phone}</span>}</div>
    </div>
  );
}
