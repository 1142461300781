import { eventChannel } from 'redux-saga';
import { getCollection } from '../../realm';
import { IMongodbUser } from '../../types/mongodb';
import { IMongodbNotification } from '../../types/mongodb/TypeNotification';
import * as Realm from 'realm-web';

export const getNotificationsForStore = async (
  locationCode: string,
  user: IMongodbUser,
  startValue?: Date,
): Promise<IMongodbNotification[] | never> => {
  try {
    const notificationCollection = getCollection('notifications');
    let filter = [];
    filter.push({ locationCode: locationCode });
    filter.push({ markedAsReadBy: { $nin: [user.email] } });
    if (startValue) {
      filter.push({ serverCreatedOn: { $lt: new Date(startValue) } });
    }
    const data = await notificationCollection.aggregate([
      { $match: { $and: filter } },
      { $sort: { serverCreatedOn: -1 } },
      { $facet: { count: [{ $count: 'totalNotificationCount' }], list: [{ $limit: 5 }] } },
    ]);

    return Realm.BSON.EJSON.parse(JSON.stringify(data[0])) as IMongodbNotification[];
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId: string, currentUser: IMongodbUser) => {
  try {
    const notificationCollection = getCollection('notifications');
    const currentDate = new Date(Date.now());
    await notificationCollection.updateOne(
      { _id: { $oid: notificationId } },
      {
        $push: { markedAsReadBy: currentUser.email },
        $set: {
          serverUpdatedOn: { $date: currentDate.toJSON() },
          updatedOn: { $date: currentDate.toJSON() },
        },
      },
    );
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};
export const getMongodbNotificationById = async (notificationId: string) => {
  try {
    const notificationCollection = getCollection('notifications');

    const notification: IMongodbNotification | undefined = await notificationCollection.findOne({
      _id: { $oid: notificationId },
    });
    return Realm.BSON.EJSON.parse(JSON.stringify(notification)) as IMongodbNotification;
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

export const watchOnNotifications = async (locationCode: string) => {
  return eventChannel(emitter => {
    const notificationsCollection = getCollection('notifications');
    const filter = {
      filter: {
        'fullDocument.locationCode': locationCode,
      },
    };
    const changeStream = notificationsCollection.watch(filter);
    const watchData = async () => {
      try {
        for await (const change of changeStream) {
          emitter(change);
        }
      } catch (e) {
        throw e;
      }
    };
    watchData();
    return () => {
      changeStream.return('closed');
    };
  });
};
