import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { AppState } from '../reducers/rootReducer';
import orderBy from 'lodash/orderBy';

const _selectNotifications = (state: AppState) => state.Notification.notifications;
export const selectNotifications = createDraftSafeSelector(
  _selectNotifications,
  payload => payload,
);
export const selectNotificationsArray = createDraftSafeSelector(_selectNotifications, payload =>
  payload.data ? orderBy(Array.from(payload.data.values()), ['serverCreatedOn'], ['desc']) : [],
);
export const selectNotificationsMap = createDraftSafeSelector(
  _selectNotifications,
  payload => payload.data,
);

const _selectMarkNotificationAsRead = (state: AppState) =>
  state.Notification.markNotificationAsRead;
export const selectMarkNotificationAsRead = createDraftSafeSelector(
  _selectMarkNotificationAsRead,
  payload => payload,
);

const _selectViewNotification = (state: AppState) => state.Notification.viewNotification;
export const selectViewNotification = createDraftSafeSelector(
  _selectViewNotification,
  payload => payload,
);
const _selectNotificationCount = (state: AppState) => state.Notification.notificationCount;
export const selectNotificationCount = createDraftSafeSelector(
  _selectNotificationCount,
  payload => payload,
);
