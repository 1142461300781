import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import orderSaga from './orderSaga';
import userSaga from './userSaga';
import storeSaga from './storeSaga';
import productSaga from './productSaga';
import companySaga from './companySaga';
import notificationSaga from './notificationSaga';
import inventorySaga from './inventorySaga';
import userPreferencesSaga from './userPreferencesSaga';

export function* rootSaga() {
  yield all([
    authSaga(),
    orderSaga(),
    storeSaga(),
    productSaga(),
    userSaga(),
    companySaga(),
    notificationSaga(),
    inventorySaga(),
    userPreferencesSaga(),
  ]);
}
