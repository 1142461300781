import clsx from 'clsx';

export interface IWaiverAddressFormProps {
  name: string;
  address: string;
  zip: string;
  city: string;
  state: string;
  phone: string;
  dob: string;
  stateId: {
    id: string;
    state: string;
  };
}

export const WaiverAddressForm = (props: IWaiverAddressFormProps) => {
  const rootRowClassName = ' flex mb-2 w-full';
  const labelClassName = 'w-[152px] flex-shrink-0 text-gray-600';
  const labelClassNameSecondary = 'w-[80px] flex-shrink-0 text-gray-600';
  const inputClassName = 'flex-auto w-full border-0 border-b  border-solid  border-black ';

  return (
    <div className=" bg-white text-black w-full text-base">
      <div className={rootRowClassName}>
        <div className={labelClassName}>NAME</div>
        <div className={inputClassName}>{props.name}</div>
      </div>
      <div className={rootRowClassName}>
        <div className={labelClassName}>ADDRESS</div>
        <div className={inputClassName}>{props.address}</div>
      </div>
      <div className={rootRowClassName}>
        <div className="flex w-full shrink-0">
          <div className="shrink-0 w-[55%] mr-7 flex">
            <div className={labelClassName}>CITY</div>
            <div className={inputClassName}>{props.city}</div>
          </div>
          <div className="flex flex-grow">
            <div className={clsx(labelClassNameSecondary)}>STATE</div>
            <div className={clsx(inputClassName)}>{props.state}</div>
          </div>
        </div>
      </div>
      <div className={rootRowClassName}>
        <div className="flex w-full shrink-0">
          <div className="shrink-0 w-[55%] mr-7 flex">
            <div className={labelClassName}>ZIP</div>
            <div className={inputClassName}>{props.zip}</div>
          </div>
          <div className="flex  flex-grow">
            <div className={labelClassNameSecondary}>PHONE</div>
            <div className={inputClassName}>{props.phone}</div>
          </div>
        </div>
      </div>
      <div className={rootRowClassName}>
        <div className="flex w-full shrink-0">
          <div className="shrink-0 w-[55%] mr-7 flex">
            <div className={labelClassName}>DATE OF BIRTH</div>
            <div className={inputClassName}>{props.dob}</div>
          </div>
          <div className="flex  flex-grow">
            {/* <div className={labelClassName}></div>
            <div className={inputClassName}></div> */}
          </div>
        </div>
      </div>
      <div className={rootRowClassName}>
        <div className="flex w-full shrink-0">
          <div className="shrink-0 w-[55%] mr-7 flex  items-end">
            <div className={labelClassName}>
              <span>DRIVER'S LICENSE</span>
              <br />
              <span># OR STATE I.D. #</span>
            </div>
            <div className={inputClassName}>{props.stateId.id}</div>
          </div>
          <div className="flex  flex-grow items-end">
            <div className={labelClassNameSecondary}>STATE</div>
            <div className={inputClassName}>{props.stateId.state}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
