import { ONE_LOGIN_ROLES, STORE_ACCESS_ROLES } from '../constants';
import { IMongodbUser } from '../types/mongodb';

export const isUserAllowedAccess = ({
  currentUserRoles,
  rolesNeeded,
}: {
  currentUserRoles: string[];
  rolesNeeded: string[];
}) => {
  let isAllowed = false;
  if (!rolesNeeded.length) isAllowed = false;
  else {
    for (const role of currentUserRoles) {
      if (rolesNeeded.includes(role)) {
        isAllowed = true;
        break;
      }
    }
  }
  return isAllowed;
};

export const getUserStoreRoleAccess = (
  user: IMongodbUser,
  locationCode: string,
): {
  isAdmin: boolean;
  isManager: boolean;
  canPack: boolean;
  canPickup: boolean;
  canPackOnly: boolean;
  canPickupOnly: boolean;
} => {
  const isAdmin = !!user?.oneLoginRoles?.find(item => ONE_LOGIN_ROLES.phantom_bopis_admin === item);
  const store = user?.storeAccess?.find(item => item.locationId === locationCode);
  const isManager = !!store?.roles?.find(
    item => item === STORE_ACCESS_ROLES.phantom_bopis_store_manager,
  );
  const canPack = !!store?.roles?.find(
    item => item === STORE_ACCESS_ROLES.phantom_bopis_store_pack,
  );
  const canPickup = !!store?.roles?.find(
    item => item === STORE_ACCESS_ROLES.phantom_bopis_store_pickup,
  );
  const canPackOnly = !isAdmin && !isManager && canPack && !canPickup;
  const canPickupOnly = !isAdmin && !isManager && !canPack && canPickup;
  return {
    isAdmin,
    isManager,
    canPack,
    canPickup,
    canPackOnly,
    canPickupOnly,
  };
};
