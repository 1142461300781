import uniq from 'lodash/uniq';
import { IOrder } from '../types/fulfillments/Order';
import { IMongodbOrder, MongoDbOrderStatusEnum } from '../types/mongodb';
import { IOrdersProduct, IShopifyOrder } from '../types/shopify/TypeOrder';
import { formatCurrency, formatDate, getDateTimeDifference } from './common';

export const getSimplifiedOrder = (
  mongodbOrder: IMongodbOrder,
  shopifyOrder: IShopifyOrder,
): IOrder => {
  const latestStatusHistory = mongodbOrder.statusHistory?.[mongodbOrder.statusHistory.length - 1];
  let packingBy = {
    name: '',
    email: '',
  };
  let unfulfilledBy = {
    name: '',
    email: '',
  };
  let substituteChangePacker = {
    name: '',
    email: '',
  };

  mongodbOrder.statusHistory?.forEach(statusHistory => {
    if (statusHistory.updatedTo === MongoDbOrderStatusEnum.PACKING_STARTED) {
      packingBy = statusHistory.updatedBy;
    }
    if (statusHistory.updatedTo === MongoDbOrderStatusEnum.UNFULFILLABLE) {
      unfulfilledBy = statusHistory.updatedBy;
    }
    if (statusHistory.updatedTo === MongoDbOrderStatusEnum.ORDER_SUBSTITUTE_EDIT) {
      substituteChangePacker = statusHistory.updatedBy;
    }
  });
  const orderPlacedOn = formatDate(new Date(mongodbOrder.createdOn));
  const lineItemMap: Map<string, IOrdersProduct> = new Map();
  const packByDateTime = mongodbOrder.packBy ? formatDate(mongodbOrder.packBy) : '';
  const timeDiff = getDateTimeDifference(new Date(Date.now()), mongodbOrder.packBy);
  let qtyPacked = 0;
  let itemsPacked: number = 0;
  const itemSubs: string[] = [];
  let qtySubs = 0;
  let valuePacked = 0;
  let hasSubstitutes = false;
  let orderQty = 0;

  shopifyOrder.lineItems?.nodes.forEach(lineItem => {
    orderQty = orderQty + lineItem.quantity;
    const sku = lineItem.variant.sku.trim() || lineItem.product.sku?.value.trim();
    if (sku) {
      const fulfillmentStatus = mongodbOrder.fulfillmentStatus?.find(
        mongodbOrder => mongodbOrder.productId === lineItem.product.legacyResourceId,
      );
      const alreadyPresent = lineItemMap.get(sku);

      if (alreadyPresent) {
        // calculation for buy x and get y things
        const totalOrderedQuantity = alreadyPresent.quantity + lineItem.quantity;
        const _lineItem: IOrdersProduct = {
          ...lineItem,
          quantity: totalOrderedQuantity,
          fulfillmentStatus: fulfillmentStatus,
          discountAllocations: [
            ...(alreadyPresent.discountAllocations || []),
            ...(lineItem.discountAllocations || []),
          ],
          discountedTotalSet: {
            shopMoney: {
              amount: (
                Number(alreadyPresent.discountedTotalSet?.shopMoney.amount || 0) +
                Number(lineItem.discountedTotalSet?.shopMoney.amount || 0)
              ).toString(),
              currencyCode:
                lineItem.discountedTotalSet?.shopMoney.currencyCode ||
                alreadyPresent.discountedTotalSet?.shopMoney.currencyCode ||
                '',
            },
          },
          originalTotalSet: {
            shopMoney: {
              amount: (
                Number(alreadyPresent.originalTotalSet.shopMoney.amount || 0) +
                Number(lineItem.originalTotalSet.shopMoney.amount || 0)
              ).toString(),
              currencyCode:
                lineItem.originalTotalSet.shopMoney.currencyCode ||
                alreadyPresent.originalTotalSet.shopMoney.currencyCode ||
                '',
            },
          },
          substitutesProductsWithFilledQty: lineItem.substitutesProducts?.map(item => {
            return {
              ...item,
              filledQty:
                fulfillmentStatus?.substitutes?.find(sub => sub.productId === item.legacyResourceId)
                  ?.filledQty || 0,
            };
          }),
        };
        lineItemMap.set(sku, _lineItem);
      } else {
        const _lineItem = {
          ...lineItem,
          fulfillmentStatus: fulfillmentStatus,
          substitutesProductsWithFilledQty: lineItem.substitutesProducts?.map(item => {
            return {
              ...item,
              filledQty:
                fulfillmentStatus?.substitutes?.find(sub => sub.productId === item.legacyResourceId)
                  ?.filledQty || 0,
            };
          }),
        };
        lineItemMap.set(sku, _lineItem);
      }
    }
  });

  Array.from(lineItemMap.values()).forEach(lineItem => {
    const fulfillmentStatus = mongodbOrder.fulfillmentStatus?.find(
      mongodbOrder => mongodbOrder.productId === lineItem.product.legacyResourceId,
    );
    const totalPackedQuantity =
      (fulfillmentStatus?.filledQty || 0) +
      (fulfillmentStatus?.substitutes?.reduce((acc, curr) => acc + curr.filledQty, 0) || 0);
    if (totalPackedQuantity === lineItem.quantity && !!totalPackedQuantity) {
      itemsPacked = itemsPacked + 1;
    }
  });

  mongodbOrder.fulfillmentStatus?.forEach(item => {
    qtyPacked = item.filledQty + qtyPacked;
    let currentFilledQty = item.filledQty > 0 ? item.filledQty : 0;
    item.substitutes?.forEach(subs => {
      if (subs.filledQty > 0) {
        currentFilledQty = currentFilledQty + subs.filledQty;
      }
    });
    const allLineItemsHavingSameProduct = shopifyOrder.lineItems.nodes.filter(
      lineItem => lineItem.product.legacyResourceId === item.productId,
    );
    allLineItemsHavingSameProduct.forEach(lineItem => {
      if (currentFilledQty > lineItem.quantity) {
        valuePacked =
          valuePacked +
          lineItem.quantity *
            Number(lineItem.discountedUnitPriceAfterAllDiscountsSet?.shopMoney.amount || 0);
        currentFilledQty = currentFilledQty - lineItem.quantity;
      } else if (!!currentFilledQty) {
        valuePacked =
          valuePacked +
          currentFilledQty *
            Number(lineItem.discountedUnitPriceAfterAllDiscountsSet?.shopMoney.amount || 0);
        currentFilledQty = 0;
      }
    });
    if (item.substitutes?.length) {
      item.substitutes.forEach(subs => {
        if (subs.filledQty > 0) {
          hasSubstitutes = true;
          itemSubs.push(item.productId);
        }
        qtySubs = qtySubs + subs.filledQty;
        qtyPacked = qtyPacked + subs.filledQty;
      });
    }
  });

  const noOfItemsSubstituted = uniq(itemSubs);

  const fulfillmentOrderId = shopifyOrder.fulfillmentOrders?.nodes?.[0].id;

  const customerName = `${shopifyOrder.customer.firstName || ''} ${shopifyOrder.customer.lastName || ''}`;
  const orderItems = lineItemMap.size;

  const unfulfilledOn =
    latestStatusHistory?.updatedTo === MongoDbOrderStatusEnum.UNFULFILLABLE &&
    latestStatusHistory.serverUpdatedOn
      ? formatDate(new Date(latestStatusHistory.serverUpdatedOn))
      : undefined;

  const transformedOrder: IOrder = {
    ...mongodbOrder,
    id: shopifyOrder.legacyResourceId,
    orderPlacedOn,
    qtyPacked,
    qtySubs,
    itemSubs: noOfItemsSubstituted.length,
    itemsPacked,
    packingBy,
    customerName,
    orderItems,
    orderQty,
    orderValue: formatCurrency(shopifyOrder.subtotalPriceSet.shopMoney.amount),
    ordersProduct: Array.from(lineItemMap.values()),
    valuePacked: formatCurrency(valuePacked),
    fulfillmentOrderId,
    unfulfilledOn,
    shopifyOrder: { ...shopifyOrder, hasSubstitutes },
    packByDateTime,
    timeDifference: timeDiff,
    unfulfilledBy,
    substituteChangePacker,
  };
  return transformedOrder;
};
