import { call, fork, put, takeEvery, take, takeLatest, select } from 'redux-saga/effects';
import { InventoryActions, OrdersActions } from '../actions';
import {
  getOrdersForStore,
  mongodbOrdersWatchEmitter,
  updateOrdersStatusHistory,
  updateOrdersFulfillmentStatus,
  markOrderReadyForPickup,
  getSubstitutesProduct,
  markOrderFulfilled,
  getOrdersCount,
  getOrder,
  findOrders,
  updateOrdersPrintingSummary,
} from '../services/orderService';
import { ActionType } from 'typesafe-actions';
import {
  selectFetchedOrdersForPages,
  selectFindOrders,
  selectOrdersMap,
} from '../selectors/orderSelector';
import { callPageFunction } from '../../utils/callPageFunction';
import { IOrder } from '../../types/fulfillments/Order';
import { getSimplifiedOrder } from '../../utils/order';
import { IMongodbOrder, IMongodbUser, MongoDbOrderStatusEnum } from '../../types/mongodb';
import { ISubstitutesProduct } from '../../types/shopify/TypeOrder';
import uniq from 'lodash/uniq';
import * as Realm from 'realm-web';
import { selectCurrentUser } from '../selectors/authSelector';

export function* getOrdersForStoreSaga(
  action: ActionType<typeof OrdersActions.getOrdersForStore.saga>,
): Generator<unknown, void, unknown> {
  try {
    const { locationCode, status } = action.payload;
    yield put(OrdersActions.getOrdersForStore.start());
    const data: any = yield call(getOrdersForStore, locationCode, status);
    if (
      status === MongoDbOrderStatusEnum.NEEDS_PACKING ||
      status === MongoDbOrderStatusEnum.PACKING_STARTED ||
      status === MongoDbOrderStatusEnum.UNFULFILLABLE
    ) {
      const skuList: string[] = [];
      (data as { orders: IOrder[] }).orders.forEach(order => {
        order.shopifyOrder.lineItems.nodes.forEach(lineItem => {
          if (lineItem.product.sku) {
            skuList.push(lineItem.product.sku.value);
          }
        });
      });
      if (skuList.length) {
        yield put(
          InventoryActions.getProductsInventories.saga({
            locationCode: locationCode,
            productsSku: uniq(skuList),
          }),
        );
      }
    }
    const prevOrders: any = yield select(selectOrdersMap);
    const _prevOrders: any = new Map(prevOrders);
    const { orders } = data as {
      orders: IOrder[];
    };
    orders.forEach(order => {
      _prevOrders.set(order._id, order);
    });
    yield put(OrdersActions.getOrdersForStore.success(_prevOrders));
  } catch (error: unknown) {
    console.log('orderSagaError', error);
    yield put(OrdersActions.getOrdersForStore.error(error as Error));
  }
}

export function* getOrderSaga(
  action: ActionType<typeof OrdersActions.getOrder.saga>,
): Generator<unknown, void, unknown> {
  try {
    const { id } = action.payload;
    yield put(OrdersActions.getOrder.start());
    const data: any = yield call(getOrder, id);
    yield put(OrdersActions.getOrder.success(data));
  } catch (error: unknown) {
    console.log('orderSagaError', error);
    yield put(OrdersActions.getOrder.error(error as Error));
  }
}

export function* watchOnOrdersSaga(
  action: ActionType<typeof OrdersActions.watchOnOrders.saga>,
): Generator<unknown, void, unknown> {
  yield put(OrdersActions.watchOnOrders.start());
  const { locationCode } = action.payload;
  console.log('started watch on: ', locationCode);
  const chan: any = yield call(mongodbOrdersWatchEmitter, locationCode);
  try {
    while (true) {
      const changed: any = yield take(chan);
      console.log('changed: ', changed);

      const {
        documentKey,
        fullDocument: updatedDocument,
        operationType,
        updateDescription,
      } = changed;
      const fullDocument = Realm.BSON.EJSON.parse(JSON.stringify(updatedDocument)) as IMongodbOrder;
      if (operationType === 'update' && fullDocument) {
        const fetchedOrdersForPages: any = yield select(selectFetchedOrdersForPages);
        const ordersMap: any = yield select(selectOrdersMap);

        // set data if we have already visited to page where order updatedTo.
        // Packing started, unfulfillable and Needs Packing ==> NEEDS_PACKING page and similarly Ready For Pick Up, Payment Failed, Payment Confirmed are at Ready for pickup page
        const shouldSetData =
          fullDocument.status === MongoDbOrderStatusEnum.PACKING_STARTED ||
          fullDocument.status === MongoDbOrderStatusEnum.UNFULFILLABLE ||
          fullDocument.status === MongoDbOrderStatusEnum.NEEDS_PACKING
            ? fetchedOrdersForPages[MongoDbOrderStatusEnum.NEEDS_PACKING]
            : fullDocument.status === MongoDbOrderStatusEnum.READY_FOR_PICKUP ||
                fullDocument.status === MongoDbOrderStatusEnum.PAYMENT_CONFIRMED ||
                fullDocument.status === MongoDbOrderStatusEnum.PAYMENT_FAILED ||
                fullDocument.status === MongoDbOrderStatusEnum.ORDER_SUBSTITUTE_EDIT
              ? fetchedOrdersForPages[MongoDbOrderStatusEnum.READY_FOR_PICKUP]
              : fetchedOrdersForPages[fullDocument.status];

        const shouldRemoveData =
          !(fullDocument as IMongodbOrder).statusHistory?.find(
            item =>
              item.updatedTo === MongoDbOrderStatusEnum.READY_FOR_PICKUP ||
              item.updatedTo === MongoDbOrderStatusEnum.PACKING_STARTED ||
              item.updatedTo === MongoDbOrderStatusEnum.UNFULFILLABLE,
          ) && fullDocument.status === MongoDbOrderStatusEnum.CANCELED;

        if (shouldSetData) {
          if (shouldRemoveData) {
            yield put(OrdersActions.removeOrder({ orderId: fullDocument._id }));
          } else {
            const previousOrder = ordersMap.get(fullDocument._id);
            if (previousOrder) {
              yield put(
                OrdersActions.updateMongodbOrder({
                  mongodbOrder: fullDocument,
                }),
              );
            } else {
              const shopifyOrder: any = yield call(
                callPageFunction,
                `/api/private/orders/getShopifyOrder?orderId=${documentKey._id}`,
              );
              yield put(
                OrdersActions.updateMongodbOrder({
                  mongodbOrder: fullDocument,
                  shopifyOrder: shopifyOrder.order,
                }),
              );
            }
          }
        }
        if (updateDescription.updatedFields?.status && fullDocument.statusHistory?.length) {
          const { updatedFrom, updatedTo } =
            fullDocument.statusHistory[fullDocument.statusHistory?.length - 1];

          yield put(
            OrdersActions.updateOrdersCount({
              updatedFrom,
              updatedTo: shouldRemoveData ? undefined : updatedTo,
            }),
          );

          // delete data if we didn't visited to page where order updatedTo
          const shouldDeleteData =
            updatedFrom === MongoDbOrderStatusEnum.PACKING_STARTED ||
            updatedFrom === MongoDbOrderStatusEnum.UNFULFILLABLE ||
            updatedFrom === MongoDbOrderStatusEnum.NEEDS_PACKING
              ? fetchedOrdersForPages[MongoDbOrderStatusEnum.NEEDS_PACKING]
              : updatedFrom === MongoDbOrderStatusEnum.READY_FOR_PICKUP ||
                  updatedFrom === MongoDbOrderStatusEnum.PAYMENT_CONFIRMED ||
                  updatedFrom === MongoDbOrderStatusEnum.PAYMENT_FAILED ||
                  updatedFrom === MongoDbOrderStatusEnum.ORDER_SUBSTITUTE_EDIT
                ? fetchedOrdersForPages[MongoDbOrderStatusEnum.READY_FOR_PICKUP]
                : fetchedOrdersForPages[updatedFrom];
          if (
            (shouldDeleteData && !shouldSetData) ||
            fullDocument.status === MongoDbOrderStatusEnum.CANCELED_RESHELVING_CONFIRMED
          ) {
            yield put(OrdersActions.removeOrder({ orderId: fullDocument._id }));
          }
        }
      } else if (operationType === 'insert') {
        const shopifyOrder: any = yield call(
          callPageFunction,
          `/api/private/orders/getShopifyOrder?orderId=${documentKey._id}`,
        );
        console.log('get shopify order: ', shopifyOrder);
        const order = getSimplifiedOrder(fullDocument, shopifyOrder.order);
        const skuList: string[] = [];
        order.shopifyOrder.lineItems.nodes.forEach(lineItem => {
          if (lineItem.product.sku) {
            skuList.push(lineItem.product.sku.value);
          }
        });
        if (skuList.length) {
          yield put(
            InventoryActions.getProductsInventories.saga({
              locationCode: locationCode,
              productsSku: uniq(skuList),
            }),
          );
        }
        yield put(
          OrdersActions.updateOrdersCount({ updatedTo: MongoDbOrderStatusEnum.NEEDS_PACKING }),
        );
        yield put(OrdersActions.setOrder({ order: order }));
      }
    }
  } catch (err) {
    console.log('error while watching changes: ', err);
    yield put(OrdersActions.watchOnOrders.error(err as Error));
  } finally {
    chan.close();
    console.log('order watch terminated for store: ', locationCode);
    yield put(OrdersActions.watchOnOrders.error(new Error('Terminated')));
  }
}
export function* updateOrdersStatusHistorySaga(
  action: ActionType<typeof OrdersActions.updateOrdersStatusHistory.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(OrdersActions.updateOrdersStatusHistory.start());
    const { updatedFrom, updatedTo, orderId, bopisStatus, locationCode } = action.payload;
    yield call(
      updateOrdersStatusHistory,
      orderId,
      updatedFrom,
      updatedTo,
      bopisStatus,
      locationCode,
    );
    yield put(OrdersActions.updateOrdersStatusHistory.success({ status: updatedTo }));
  } catch (err) {
    yield put(OrdersActions.updateOrdersStatusHistory.error(err as Error));
  }
}
export function* updateOrdersFulfillmentStatusSaga(
  action: ActionType<typeof OrdersActions.updateOrdersFulfillmentStatus.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(OrdersActions.updateOrdersFulfillmentStatus.start());
    const orders = yield select(selectOrdersMap);
    const currentUser = yield select(selectCurrentUser);
    const { orderId, fulfillmentStatus, locationCode } = action.payload;
    const orderInfo = (orders as Map<string, IOrder>).get(orderId);
    if (orderInfo && currentUser) {
      yield call(
        updateOrdersFulfillmentStatus,
        orderId,
        fulfillmentStatus,
        locationCode,
        orderInfo,
        currentUser as IMongodbUser,
      );
    }
    yield put(OrdersActions.updateOrdersFulfillmentStatus.success());
  } catch (err) {
    yield put(OrdersActions.updateOrdersFulfillmentStatus.error(err as Error));
  }
}
export function* updateOrdersPrintingSummarySaga(
  action: ActionType<typeof OrdersActions.updateOrdersPrintingSummary.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(OrdersActions.updateOrdersPrintingSummary.start());
    const currentUser = yield select(selectCurrentUser);
    const { invoice, packingSlip, waiverForm, orderId } = action.payload;

    if (currentUser) {
      yield call(
        updateOrdersPrintingSummary,
        orderId,
        invoice,
        packingSlip,
        waiverForm,
        currentUser as IMongodbUser,
      );
    }
    yield put(OrdersActions.updateOrdersPrintingSummary.success());
  } catch (err) {
    yield put(OrdersActions.updateOrdersPrintingSummary.error(err as Error));
  }
}
export function* markOrderReadyForPickupSaga(
  action: ActionType<typeof OrdersActions.markOrderReadyForPickup.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(OrdersActions.markOrderReadyForPickup.start());
    const { fulfillmentOrderId, orderId, totalQty } = action.payload;
    yield call(markOrderReadyForPickup, fulfillmentOrderId, orderId, totalQty);
    yield put(OrdersActions.markOrderReadyForPickup.success());
  } catch (err) {
    yield put(OrdersActions.markOrderReadyForPickup.error(err as Error));
  }
}
export function* getSubstitutesProductSaga(
  action: ActionType<typeof OrdersActions.getSubstitutesProduct.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(OrdersActions.getSubstitutesProduct.start());
    const { productId, productType, price, orderId, nodes, locationCode } = action.payload;
    const products: any = yield call(getSubstitutesProduct, productType, price, nodes);
    const skuList: string[] = [];
    (products as ISubstitutesProduct[]).forEach(product => {
      if (product.sku?.value) {
        skuList.push(product.sku.value);
      }
    });
    yield put(
      OrdersActions.setProductsSubstitutes({ productId, orderId, substitutesProducts: products }),
    );
    if (skuList.length) {
      yield put(
        InventoryActions.getProductsInventories.saga({
          locationCode: locationCode,
          productsSku: uniq(skuList),
        }),
      );
    }
    yield put(OrdersActions.getSubstitutesProduct.success());
  } catch (err) {
    yield put(OrdersActions.getSubstitutesProduct.error(err as Error));
  }
}
export function* markOrderFulfilledSaga(
  action: ActionType<typeof OrdersActions.markOrderFulfilled.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(OrdersActions.markOrderFulfilled.start());
    const { orderId, locationCode, updatedFrom } = action.payload;
    const res: any = yield call(markOrderFulfilled, orderId, locationCode, updatedFrom);
    if (res.shopifyOrder) {
      yield put(OrdersActions.updateMongodbOrder({ shopifyOrder: res.shopifyOrder }));
    }
    yield put(OrdersActions.markOrderFulfilled.success(res));
  } catch (err) {
    yield put(OrdersActions.markOrderFulfilled.error(err as Error));
  }
}
export function* getOrdersCountSaga(
  action: ActionType<typeof OrdersActions.getOrdersCount.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(OrdersActions.getOrdersCount.start());
    const { locationCode } = action.payload;
    const counts: any = yield call(getOrdersCount, locationCode);
    yield put(OrdersActions.getOrdersCount.success(counts));
  } catch (err) {
    yield put(OrdersActions.getOrdersCount.error(err as Error));
  }
}
export function* findOrdersSaga(
  action: ActionType<typeof OrdersActions.findOrders.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(OrdersActions.findOrders.start());
    const { queries, locationCode, startValue, reset = true, sortBy, sortKey } = action.payload;
    const foundOrders: any = yield select(selectFindOrders);
    const { orders, count }: any = yield call(
      findOrders,
      queries,
      locationCode,
      startValue,
      sortKey,
      sortBy,
    ) as any;
    if (reset) {
      yield put(OrdersActions.findOrders.success({ orders, count }));
    } else {
      yield put(
        OrdersActions.findOrders.success({
          orders: [...(foundOrders.data.orders || []), ...orders],
          count,
        }),
      );
    }
  } catch (err) {
    yield put(OrdersActions.findOrders.error(err as Error));
  }
}

function* getOrdersForStoreListener() {
  yield takeLatest(OrdersActions.getOrdersForStore.saga.toString(), getOrdersForStoreSaga);
}
function* getOrderListener() {
  yield takeEvery(OrdersActions.getOrder.saga.toString(), getOrderSaga);
}
function* watchOnOrdersListener() {
  yield takeLatest(OrdersActions.watchOnOrders.saga.toString(), watchOnOrdersSaga);
}
function* updateOrdersStatusHistoryListener() {
  yield takeEvery(
    OrdersActions.updateOrdersStatusHistory.saga.toString(),
    updateOrdersStatusHistorySaga,
  );
}
function* updateOrdersFulfillmentStatusListener() {
  yield takeEvery(
    OrdersActions.updateOrdersFulfillmentStatus.saga.toString(),
    updateOrdersFulfillmentStatusSaga,
  );
}

function* updateOrdersPrintingSummaryListener() {
  yield takeEvery(
    OrdersActions.updateOrdersPrintingSummary.saga.toString(),
    updateOrdersPrintingSummarySaga,
  );
}
function* markOrderReadyForPickupListener() {
  yield takeEvery(
    OrdersActions.markOrderReadyForPickup.saga.toString(),
    markOrderReadyForPickupSaga,
  );
}
function* getSubstitutesProductListener() {
  yield takeEvery(OrdersActions.getSubstitutesProduct.saga.toString(), getSubstitutesProductSaga);
}
function* markOrderFulfilledListener() {
  yield takeEvery(OrdersActions.markOrderFulfilled.saga.toString(), markOrderFulfilledSaga);
}
function* getOrdersCountListener() {
  yield takeLatest(OrdersActions.getOrdersCount.saga.toString(), getOrdersCountSaga);
}
function* findOrdersListener() {
  yield takeLatest(OrdersActions.findOrders.saga.toString(), findOrdersSaga);
}
export default function* orderSaga() {
  yield fork(getOrdersForStoreListener);
  yield fork(getOrderListener);
  yield fork(watchOnOrdersListener);
  yield fork(updateOrdersStatusHistoryListener);
  yield fork(updateOrdersFulfillmentStatusListener);
  yield fork(markOrderReadyForPickupListener);
  yield fork(getSubstitutesProductListener);
  yield fork(markOrderFulfilledListener);
  yield fork(getOrdersCountListener);
  yield fork(findOrdersListener);
  yield fork(updateOrdersPrintingSummaryListener);
}
