import { FetchUserPreferences } from '../FetchUserPreferences';
import { ValidateUserRoles } from '../ValidateUserRoles';

export const ProtectedRouteLayout = () => {
  return (
    <ValidateUserRoles>
      <FetchUserPreferences />
    </ValidateUserRoles>
  );
};
