import { actionCreator, asyncActionCreator } from '../../redux/helper';
import { IStore } from '../../types';
import { StoreActionType, IStoreAction } from '../../../server/stores';
import Delta, { Op } from 'quill-delta';

enum StoreActionTypes {
  FETCH_STORES = 'STORE/FETCH_STORES',
  UPDATE_STORES = 'STORE/UPDATE_STORES',
  SET_STORES = 'STORE/SET_STORES',
  SET_STORES_FOR_USER = 'STORE/SET_STORES_FOR_USER',
  WATCH_STORES = 'STORE/WATCH_STORES',
  UPDATE_WAIVER_FORM = 'STORE/UPDATE_WAIVER_FORM',
}

export const fetchStores = asyncActionCreator<IStore[]>(StoreActionTypes.FETCH_STORES);
interface UpdateStoresSagaPayload<T extends StoreActionType = StoreActionType> {
  actionType: T;
  updatePayload: Parameters<IStoreAction[T]>[1];
}

export const updateStores = asyncActionCreator<undefined, UpdateStoresSagaPayload>(
  StoreActionTypes.UPDATE_STORES,
);

export const updateWaiverForm = asyncActionCreator<
  undefined,
  {
    storeId: string;
    content: Delta | Op[];
  }
>(StoreActionTypes.UPDATE_WAIVER_FORM);

export const setStores = actionCreator<IStore[]>(StoreActionTypes.SET_STORES);
export const setStoresForUser = actionCreator<IStore[]>(StoreActionTypes.SET_STORES_FOR_USER);
export const watchStores = actionCreator(StoreActionTypes.WATCH_STORES);
