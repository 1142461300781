import { z } from 'zod';

export const MongodbProductSchema = z.object({
  _id: z.string(),
  sku: z.string(),
  shopifyId: z.string(),
  itemCode: z.string(),
  title: z.string(),
  bopis: z.boolean(),
  createdOn: z.string(),
  serverCreatedOn: z.string(),
  updatedOn: z.string(),
  serverUpdatedOn: z.string(),
});

export type IMongodbProduct = z.infer<typeof MongodbProductSchema>;
