import { Switch } from 'antd';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useDispatch } from 'react-redux';
import { ProductActions } from '../../../redux/actions';
import { logEvent } from '../../../utils/EventLogger';
import { EMongodbEventType } from '../../../types/mongodb';

export const CustomCellBopis = (props: CustomCellRendererProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    props.setValue?.(!props.value);
    dispatch(
      ProductActions.updateProducts.saga({
        actionType: 'updateBopisStatus',
        updatePayload: {
          products: [
            {
              shopifyId: props.data.id,
              bopisStatus: !props.value,
            },
          ],
        },
      }),
    );

    logEvent({
      type: props.value
        ? EMongodbEventType.Enum['bopis.admin.disable-bopis']
        : EMongodbEventType.Enum['bopis.admin.enable-bopis'],
    });
  };

  return <Switch checked={props.value} onClick={handleClick} />;
};
