import { Tooltip } from 'antd';
import { Link, LinkProps } from 'react-router-dom';

interface IMenuItemLabelProps {
  icon?: React.ReactNode;
  title: string;
  to?: LinkProps['to'];
  isMenuCollapsed?: boolean;
  showTooltip?: boolean;
}

export const MenuItemLabel = ({
  icon,
  title,
  to,
  isMenuCollapsed,
  showTooltip = true,
}: IMenuItemLabelProps) => {
  const baseComponent = (
    <>
      {!!icon && <span className="leading-[0]"> {icon}</span>}
      {!!title && !isMenuCollapsed && <span>{title}</span>}
    </>
  );

  const rootClassName = 'flex items-center w-full h-full gap-x-2';

  let component = <div className={rootClassName}>{baseComponent}</div>;

  if (to) {
    component = (
      <Link to={to} className={rootClassName}>
        {baseComponent}
      </Link>
    );
  }

  if (isMenuCollapsed && showTooltip) {
    return (
      <Tooltip title={title} trigger="hover" placement="right">
        {component}
      </Tooltip>
    );
  }

  return <>{component}</>;
};
