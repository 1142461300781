import { InitialAsyncState, asyncCaseCreator } from '../helper';
import { EReduxAsyncState } from '../../types/redux';
import { NotificationActions } from '../actions';
import { createReducer } from '@reduxjs/toolkit';
import { IMongodbNotification } from '../../types/mongodb/TypeNotification';

interface INotificationState {
  notifications: InitialAsyncState<Map<string, IMongodbNotification>>;
  markNotificationAsRead: InitialAsyncState;
  watchOnNotifications: InitialAsyncState;
  viewNotification: InitialAsyncState<{ url: '' }>;
  notificationCount: number;
}

const defaultOrderState: INotificationState = {
  notifications: { status: EReduxAsyncState.idle, data: new Map() },
  markNotificationAsRead: { status: EReduxAsyncState.idle },
  watchOnNotifications: { status: EReduxAsyncState.idle },
  viewNotification: { status: EReduxAsyncState.idle, data: { url: '' } },
  notificationCount: 0,
};

export const notificationReducer = createReducer(defaultOrderState, builder => {
  asyncCaseCreator(NotificationActions.getNotificationsForStore, 'notifications', builder);
  asyncCaseCreator(NotificationActions.markNotificationAsRead, 'markNotificationAsRead', builder);
  asyncCaseCreator(NotificationActions.watchOnNotifications, 'watchOnNotifications', builder);
  asyncCaseCreator(NotificationActions.viewNotification, 'viewNotification', builder);
  builder.addCase(NotificationActions.setNotification, (state, action) => {
    const { notification } = action.payload;
    if (Array.isArray(notification)) {
      const tempMap = new Map(state.notifications.data);
      notification.forEach(item => {
        tempMap.set(item._id.toString(), item);
      });
      state.notifications.data = tempMap;
    } else {
      state.notifications.data?.set(notification._id.toString(), notification);
    }
  });
  builder.addCase(NotificationActions.deleteNotification, (state, action) => {
    const { id } = action.payload;
    state.notifications.data?.delete(id);
  });
  builder.addCase(NotificationActions.setNotificationCount, (state, action) => {
    const { count } = action.payload;
    state.notificationCount = count;
  });
});
