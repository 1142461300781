import { Navigate, useLocation, useMatches, UIMatch, Outlet, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { isUserAllowedAccess } from '../../utils/authUtils';
import { InvalidRoles } from './InvalidRoles';
import { useMemo } from 'react';
import { IRouteHandle } from '../../types';
import { RouteNames } from '../../types';

export const ValidateUserRoles = (props: React.PropsWithChildren) => {
  const location = useLocation();
  const matches = useMatches() as UIMatch<any, IRouteHandle>[];
  const currentUser = useAppSelector(state => state.Auth.currentUser);
  const userRoles = [...(currentUser?.oneLoginRoles || [])];
  const rolesNeeded = useMemo(() => {
    let rolesNeeded: IRouteHandle['requiredOneLoginRoles'] = [];
    matches.forEach(match => {
      if (match.handle?.requiredOneLoginRoles?.length) {
        rolesNeeded = match.handle?.requiredOneLoginRoles;
      }
    });
    return rolesNeeded;
  }, [matches]);

  if (
    currentUser &&
    isUserAllowedAccess({
      currentUserRoles: userRoles,
      rolesNeeded,
    })
  ) {
    return <>{props.children}</>;
  }

  if (
    currentUser &&
    !isUserAllowedAccess({
      currentUserRoles: userRoles,
      rolesNeeded,
    })
  ) {
    return <InvalidRoles rolesNeeded={rolesNeeded} userRoles={userRoles} />;
  }

  return <Navigate to={RouteNames.Login} state={{ from: location }} replace />;
};
