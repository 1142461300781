import { Space, Typography, Card, Tag, theme } from 'antd';
import { DirectionIcon, PhoneIcon } from '../../Icons';
import 'leaflet/dist/leaflet.css';
import clsx from 'clsx';
import { IStore } from '../../../types';
import { EStoreBopisStatus, getDirectionUrl, getStoreBopisStatus } from '../../../utils';
import { StoreHours } from './StoreHours';
import { PropsWithChildren } from 'react';
import { StorePosition } from './StorePosition';

const { useToken } = theme;

interface IStoreCardBaseProps {
  store: IStore;
  className?: string;
  position?: number;
}

export const StoreCardBase = ({
  store,
  className,
  position,
  children,
}: PropsWithChildren<IStoreCardBaseProps>) => {
  const { token } = useToken();
  const bopisStatus = getStoreBopisStatus(store);
  return (
    <Card
      className={clsx(' border-colorPrimary', className)}
      classNames={{
        body: '!p-2',
      }}
    >
      <div className="flex justify-between gap-2 items-center mb-2">
        <Space>
          {position && <StorePosition position={position} />}
          <span className="text-lg">{`${store.title} (${store.locationCode})`}</span>
        </Space>
        {bopisStatus !== EStoreBopisStatus.NoBopis && (
          <Tag
            color={
              bopisStatus === EStoreBopisStatus.Active ? token.colorSuccess : token.colorTextBase
            }
            className={clsx('m-0', bopisStatus !== EStoreBopisStatus.Active && '!text-colorBgBase')}
          >
            {bopisStatus}
          </Tag>
        )}
      </div>

      <div className="flex flex-row gap-4 mb-3">
        <div className=" basis-3/5 flex-shrink-0">
          <StoreHours storeHours={store.storeHours} className="mb-2" />
          <div className="flex justify-between items-center mb-2 gap-4">
            <Typography.Link
              href={getDirectionUrl(store)}
              type="secondary"
              target="_blank"
              className="hover:!text-colorTextSecondary"
            >
              {`${store.addressStreet}, ${store.addressCity}, ${store.addressState} ${store.addressZip}`}
            </Typography.Link>
            <Typography.Link
              href={getDirectionUrl(store)}
              type="secondary"
              target="_blank"
              className="w-9 h-9 rounded-full flex justify-center items-center text-xl flex-shrink-0 hover:cursor-pointer bg-colorFillQuaternary hover:bg-colorPrimary hover:!text-colorTextBase"
            >
              <DirectionIcon />
            </Typography.Link>
          </div>
          {!!store.phone && (
            <div className="flex justify-between items-center mb-2 gap-4">
              <Typography.Link
                href={`tel:${store.phone}`}
                type="secondary"
                target="_blank"
                className="hover:!text-colorTextSecondary"
              >
                {store.phone}
              </Typography.Link>
              <Typography.Link
                href={`tel:${store.phone}`}
                type="secondary"
                target="_blank"
                className="w-9 h-9 rounded-full flex justify-center items-center text-xl flex-shrink-0 hover:cursor-pointer bg-colorFillQuaternary hover:bg-colorPrimary hover:!text-colorTextBase"
              >
                <PhoneIcon />
              </Typography.Link>
            </div>
          )}

          {!!store.curbsidePhoneNumber && (
            <div className="flex justify-between items-center mb-2 gap-4">
              <Typography.Link
                href={`tel:${store.curbsidePhoneNumber}`}
                type="secondary"
                target="_blank"
                className="hover:!text-colorTextSecondary"
              >
                <span>Curbside:</span>
                <span> </span>
                <span>{store.curbsidePhoneNumber}</span>
              </Typography.Link>
              <Typography.Link
                href={`tel:${store.curbsidePhoneNumber}`}
                type="secondary"
                target="_blank"
                className="w-9 h-9 rounded-full flex justify-center items-center text-xl flex-shrink-0 hover:cursor-pointer bg-colorFillQuaternary hover:bg-colorPrimary hover:!text-colorTextBase"
              >
                <PhoneIcon />
              </Typography.Link>
            </div>
          )}

          <Space align="center">
            <Typography.Text type="secondary" strong>
              Manager:
            </Typography.Text>
            <Typography.Text type="secondary">{store.manager || ''}</Typography.Text>
          </Space>
        </div>
        <div className=" flex-auto">
          {!!store.previewMedia?.url && (
            <img src={store.previewMedia.url} alt="store" className="w-full" />
          )}
        </div>
      </div>
      {children}
    </Card>
  );
};
