import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { UserActions } from '../../redux/actions';

export const useFetchUsers = (fetch = false) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(state => state.User.users);

  useEffect(() => {
    if (fetch) {
      dispatch(UserActions.getUsers.saga());
    }
  }, []);

  return users;
};
