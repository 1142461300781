import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { EAppErrorCode } from '../types';
import map from 'lodash/map';
import { ITimeDifference } from '../types/fulfillments/Order';
import { PackingDelayedFirstNotificationEnum } from '../types/mongodb';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export const getSingularOrPluralString = (
  count: number,
  singularText: string,
  pluralText: string,
) => {
  if (count > 1) {
    return `${count} ${pluralText}`;
  } else if (count === 0) {
    return `No ${pluralText}`;
  }
  return `${count} ${singularText}`;
};

export class AppError extends Error {
  status: number;
  code: `${EAppErrorCode}` = EAppErrorCode.SOMETHING_WENT_WRONG;
  constructor(code: `${EAppErrorCode}`, message: string = '', status?: number) {
    super(message);
    this.code = code;
    this.status = status || 500;
    this.name = 'AppError';
  }
}

export const convertArrayToMap = <T>(arr: Array<T>, key: keyof T): Map<string, T> => {
  const newMap: Map<string, T> = new Map(
    map(arr, (obj: any) => [String(obj[key.toString()]), obj]),
  );
  return newMap;
};

export function formatDate(date: Date | string): string {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
}

export function formatCurrency(amount: string | number, currency = 'USD') {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(
    Number(amount),
  );
}
export function getDateTimeDifference(startDate: Date, endDate: Date): ITimeDifference {
  const startDateTime = dayjs(startDate);
  const endDateTime = dayjs(endDate);
  let hoursDifference = endDateTime.diff(startDateTime, 'hour');
  let minutesDifference = endDateTime.diff(startDateTime, 'minute');

  if (startDateTime.isAfter(endDateTime)) {
    hoursDifference = -Math.abs(hoursDifference);
    minutesDifference = -Math.abs(minutesDifference);
  }
  minutesDifference -= hoursDifference * 60;

  return {
    hours: hoursDifference,
    minutes: minutesDifference,
  };
}

export function getDifference(date1: Date, date2: Date) {
  const start = dayjs(date1);
  const end = dayjs(date2);
  const diff = dayjs.duration(end.diff(start));

  const days = diff.days();
  const hours = diff.hours();
  const minutes = diff.minutes();

  let result = '';
  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''}`;
  }
  if (hours > 0 || days > 0) {
    if (days > 0) {
      result += ', ';
    }
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  if (minutes > 0) {
    if (days > 0 || hours > 0) {
      result += ', ';
    }
    result += `${minutes} min${minutes > 1 ? 's' : ''}`;
  }

  return result.trim();
}

export function getPackingDelayedWarningMessages(
  packByDate: Date,
  timeZoneId: string,
  bufferTime?: PackingDelayedFirstNotificationEnum,
): {
  isDelayed: boolean;
  hasPackByTimePassed: boolean;
  message?: string;
} {
  const currentTime = dayjs.tz(new Date(), timeZoneId);
  const packByTime = dayjs.tz(packByDate, timeZoneId);
  const minutesDifference = Math.abs(currentTime.diff(packByTime, 'minute'));
  let bufferTimeInMins = 60;
  switch (bufferTime) {
    case PackingDelayedFirstNotificationEnum.Enum['30 mins']:
      bufferTimeInMins = 30;
      break;
    case PackingDelayedFirstNotificationEnum.Enum['15 mins']:
      bufferTimeInMins = 15;
      break;
    case PackingDelayedFirstNotificationEnum.Enum['1 hr']:
      bufferTimeInMins = 60;
      break;
    case PackingDelayedFirstNotificationEnum.Enum['2 hrs']:
      bufferTimeInMins = 120;
      break;
    case PackingDelayedFirstNotificationEnum.Enum['3 hrs']:
      bufferTimeInMins = 180;
      break;
    case PackingDelayedFirstNotificationEnum.Enum['4 hrs']:
      bufferTimeInMins = 240;
      break;
    default:
      bufferTimeInMins = 60;
      break;
  }

  if (packByTime < currentTime) {
    const timeDiffStr = getDifference(packByTime.toDate(), currentTime.toDate());
    if (timeDiffStr.length) {
      return {
        isDelayed: true,
        hasPackByTimePassed: true,
        message: `Packing delayed by ${timeDiffStr}`,
      };
    }
    return {
      isDelayed: true,
      hasPackByTimePassed: true,
      message: `Packing delayed by 1 min`,
    };
  }

  if (minutesDifference <= bufferTimeInMins) {
    const timeDiffStr = getDifference(currentTime.toDate(), packByTime.toDate());
    if (timeDiffStr.length) {
      return {
        isDelayed: true,
        hasPackByTimePassed: dayjs(packByDate) < currentTime,
        message: `Packing deadline in ${timeDiffStr}`,
      };
    }
    return {
      isDelayed: true,
      hasPackByTimePassed: dayjs(packByDate) < currentTime,
      message: `Packing deadline in 1 min`,
    };
  }

  return {
    isDelayed: false,
    hasPackByTimePassed: false,
  };
}
