import clsx from 'clsx';

export interface IWaiverSignatureFormProps {
  customer: string;
  date: string;
  time: string;
  source: string;
}

export const WaiverSignatureForm = (props: IWaiverSignatureFormProps) => {
  const rootRowClassName = ' flex mb-2 w-full';
  const labelClassName = 'flex-shrink-0 text-gray-600';
  const inputClassName = 'flex-auto w-full border-0 border-b  border-solid  border-black ';

  return (
    <div className=" bg-white text-black w-full text-base">
      <div className={rootRowClassName}>
        <div className="flex w-full shrink-0">
          <div className="shrink-0 w-[45%] mr-7 flex items-end">
            <div className={clsx(labelClassName, 'w-[100px]')}>Purchaser's Signature</div>
            <div className={inputClassName}></div>
          </div>
          <div className="flex  flex-grow items-end mr-4">
            <div className={clsx(labelClassName, 'mr-3')}>DATE:</div>
            <div className={inputClassName}>{props.date}</div>
          </div>
          <div className="flex  flex-grow items-end">
            <div className={clsx(labelClassName, 'mr-3')}>TIME:</div>
            <div className={inputClassName}>{props.time}</div>
          </div>
        </div>
      </div>
      <div className={rootRowClassName}>
        <div className="flex w-full shrink-0">
          <div className="shrink-0 w-[55%] mr-7 flex items-end">
            <div className={clsx(labelClassName, 'w-[100px]')}>Customer#:</div>
            <div className={inputClassName}>{props.customer}</div>
          </div>
          <div className="flex  flex-grow items-end">
            <div className={clsx(labelClassName, 'mr-3')}>SOURCE:</div>
            <div className={inputClassName}>{props.source}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
