import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../../redux/helper';
import { EReduxAsyncState } from '../../types/redux';
import { ProductActions, CommonActions } from '../../redux/actions';
import { IShopifyProduct } from '../../types/shopify';
import { IProduct } from '../../types';
import { IInventory } from '../../types/fulfillments/Inventory';
import groupBy from 'lodash/groupBy';

interface IProductState {
  products: InitialAsyncState<IProduct[]>;
  updateProducts: InitialAsyncState<IShopifyProduct[]>;
  inventories: Record<string, IInventory[]>;
}

const defaultProductState: IProductState = {
  products: { status: EReduxAsyncState.idle },
  updateProducts: { status: EReduxAsyncState.idle },
  inventories: {},
};

export const productReducer = createReducer(defaultProductState, builder => {
  asyncCaseCreator(ProductActions.fetchProducts, 'products', builder);
  asyncCaseCreator(ProductActions.updateProducts, 'updateProducts', builder);
  builder
    .addCase(ProductActions.setProducts, (state, action) => {
      state.products.data = action.payload;
    })
    .addCase(ProductActions.setInventories, (state, action) => {
      const inventories = action.payload || [];
      const inventoriesGroupBySku = groupBy(inventories, inventory => inventory.product);
      state.inventories = inventoriesGroupBySku;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultProductState;
    });
});
