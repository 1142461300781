import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Quill, { EmitterSource } from 'quill';
import Delta, { Op } from 'quill-delta';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

interface IQuillEditorProps {
  quillRef?: React.MutableRefObject<Quill | null>;
  onTextChange?: (delta: Delta, oldContent: Delta, source: EmitterSource) => void;
  defaultValue?: Delta | Op[];
}

export const QuillEditor = ({ quillRef, onTextChange, defaultValue }: IQuillEditorProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const defaultValueRef = useRef(defaultValue);
  const onTextChangeRef = useRef(onTextChange);
  // const onSelectionChangeRef = useRef(onSelectionChange);

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
    // onSelectionChangeRef.current = onSelectionChange;
  });

  // useEffect(() => {
  //   ref.current?.enable(!readOnly);
  // }, [ref, readOnly]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const editorContainer = container.appendChild(container.ownerDocument.createElement('div'));
      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
          // toolbar: '#quill-editor-toolbar',
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ align: [] }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
          ],
        },
      });
      if (quillRef) {
        quillRef.current = quill;
      }

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      // quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      //   onSelectionChangeRef.current?.(...args);
      // });
    }

    return () => {
      if (quillRef) quillRef.current = null;
      if (container) container.innerHTML = '';
    };
  }, [quillRef]);

  return <div className="phantom-quill-editor" ref={containerRef}></div>;
};

QuillEditor.displayName = 'QuillEditor';
