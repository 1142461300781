import { IMongodbNotification } from '../../types/mongodb/TypeNotification';
import { actionCreator, asyncActionCreator } from '../helper/index';

enum NotificationsActionTypes {
  GET_NOTIFICATIONS_FOR_STORE = 'NOTIFICATION/GET_NOTIFICATIONS_FOR_STORE',
  MARK_NOTIFICATION_AS_READ = 'NOTIFICATION/MARK_NOTIFICATION_AS_READ',
  VIEW_NOTIFICATION = 'NOTIFICATION/VIEW_NOTIFICATION',
  SET_NOTIFICATION = 'NOTIFICATION/SET_NOTIFICATION',
  DELETE_NOTIFICATION = 'NOTIFICATION/DELETE_NOTIFICATION',
  SET_NOTIFICATION_COUNT = 'NOTIFICATION/SET_NOTIFICATION_COUNT',
  WATCH_ON_NOTIFICATION = 'NOTIFICATION/WATCH_ON_NOTIFICATION',
}

export const getNotificationsForStore = asyncActionCreator<
  Map<string, IMongodbNotification> | undefined,
  {
    locationCode: string;
    startValue?: Date;
  }
>(NotificationsActionTypes.GET_NOTIFICATIONS_FOR_STORE);
export const watchOnNotifications = asyncActionCreator<
  undefined,
  {
    locationCode: string;
  }
>(NotificationsActionTypes.WATCH_ON_NOTIFICATION);
export const markNotificationAsRead = asyncActionCreator<
  { url: string } | undefined,
  {
    notificationId: string;
    read?: boolean;
    locationCode: string;
  }
>(NotificationsActionTypes.MARK_NOTIFICATION_AS_READ);
export const viewNotification = asyncActionCreator<
  { url: string } | undefined,
  {
    notificationId: string;
    locationCode: string;
  }
>(NotificationsActionTypes.VIEW_NOTIFICATION);
export const setNotification = actionCreator<{
  notification: IMongodbNotification | IMongodbNotification[];
}>(NotificationsActionTypes.SET_NOTIFICATION);
export const deleteNotification = actionCreator<{ id: string }>(
  NotificationsActionTypes.DELETE_NOTIFICATION,
);
export const setNotificationCount = actionCreator<{ count: number }>(
  NotificationsActionTypes.SET_NOTIFICATION_COUNT,
);
