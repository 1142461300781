import { useEffect, useRef } from 'react';
import { Table, ITableProps } from '../../Table';
import { IProduct } from '../../../types';
import { AgGridReact } from 'ag-grid-react';

interface IProductsTableProps {
  rowData: IProduct[];
}

export const ProductsTable = (props: IProductsTableProps & Partial<ITableProps>) => {
  const { rowData, tableRef, ...rest } = props;

  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    if (tableRef) {
      tableRef.current = gridRef.current;
    }
  }, [gridRef.current]);

  return (
    <Table
      {...rest}
      tableRef={gridRef}
      suppressRowClickSelection
      rowData={rowData}
      rowSelection="multiple"
      pagination
      paginationPageSizeSelector={[25, 50]}
      paginationPageSize={50}
      getRowId={params => params.data?.id}
    />
  );
};
